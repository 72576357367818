<template>
    <div class="h-full w-[97%] flex flex-col ml-2 mt-4 text-[#61696F]">
        <div class="h-1/5 w-full flex">
            <!--
            目前沒有Attribute功能
            <div class="my-2 border border-[#66b2ff] flex flex-col w-1/6 rounded">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-2 leading-8">Type</span>
                </div>
                <div class="flex flex-col my-1 ml-2">
                    <a-radio-group v-model="case_activity" @change="onChange" class="flex flex-col w-full">
                        <a-radio  value="case_attribute">
                            <span>Case</span>
                        </a-radio>
                        <a-radio  value="activity_attribute">
                            <span>Activity</span>
                        </a-radio>
                    </a-radio-group>
                </div>
            </div>-->
            <div class="my-2 border border-[#66b2ff] flex flex-col w-1/6 rounded">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-2 leading-8">Refine</span>
                </div>
                <div class="flex flex-col my-1 ml-2">
                    <a-radio-group v-model="is_exclude" @change="onChange" class="flex flex-col w-full">
                        <a-radio  value="false">
                            <span>Include</span>
                        </a-radio>
                        <a-radio  value="true">
                            <span>Exclude</span>
                        </a-radio>
                    </a-radio-group>
                </div>
            </div>
            <div class="ml-4 my-2 border border-[#66b2ff] flex flex-col w-2/6 rounded">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-2 leading-8">Containment</span>
                </div>
                <div class="flex flex-col my-2 ml-2">
                    <a-radio-group v-model="containment" @change="onChange" class="flex w-full grid-cols-2 gap-2">
                        <div class="flex flex-col">
                            <a-radio  value="occurred-in">
                                <span>Contained in</span>
                            </a-radio>
                            <a-radio  value="started-in">
                                <span>Started in</span>
                            </a-radio>
                        </div>
                        <div class="flex flex-col">
                            <a-radio  value="completed-in">
                                <span>End in</span>
                            </a-radio>
                            <a-radio  value="occurred-around">
                                <span>Active in</span>
                            </a-radio>
                        </div>
                    </a-radio-group>
                </div>

            </div>
        </div>
        <div class="h-[70%] w-full flex">
            <div class="my-2 border border-[#66b2ff] flex flex-col h-full rounded w-full">
                <div class="border-b border-[#66b2ff]">
                        <span class="text-[#66b2ff] ml-4 leading-8">Range selection</span>
                    </div>
                    <div class="mx-2 flex justify-center" id="RangePic"></div>
                    <div class="w-full flex justify-center">
                        <a-slider range v-model="SelectArea" :marks="mark" :step="nstep" class="ml-8 w-[80%]" @change="onChangeSlider" :tooltipVisible="false" />
                    </div>
                    <div class="flex items-center ml-8 justify-center">
                        from
                        <a-date-picker
                            v-model="selectData.startDate"
                            :disabled-date="disabledStartDate"
                            format="YYYY-MM-DD"
                            placeholder="Start"
                            @openChange="handleStartOpenChange"
                            class="mx-2"
                        />
                        <a-time-picker v-model="selectData.startTime" format="HH:mm:ss" class="mx-2" />
                        to
                        <a-date-picker
                            v-model="selectData.endDate"
                            :disabled-date="disabledEndDate"
                            format="YYYY-MM-DD"
                            placeholder="End"
                            :open="endOpen"
                            @openChange="handleEndOpenChange"
                            class="mx-2"
                        />
                        <a-time-picker v-model="selectData.endTime" format="HH:mm:ss" class="mx-2" />
                    </div>
            </div>
        </div>
        <div class="mt-4 h-[10%] w-full flex justify-end">
            <a-button ghost type="primary" class="mr-2">Reset</a-button>
            <a-button ghost type="primary" class="" @click="submit">Apply</a-button>
        </div>
    </div>
</template>
<script>
import * as d3 from "d3";
import TimeAreaChart from "../../../module/TimeAreaChart.js"
import { mapGetters,mapActions } from 'vuex';
import { message } from 'ant-design-vue';
import moment from 'moment';
import TimeRange from '../../../module/TimeRange.js';
export default {
    computed:{
        ...mapGetters({
            Timeframes: "Filter/get_Filter_timeframe",
            FetchStatus:"Filter/get_FilterFetchStatus",
        }),
    },
    watch:{
        FetchStatus:{
            handler:function(val,oldVal){
                message.loading('loading ...',0) 
                if(val === 'finish'){
                    this.GetTimeframes()
                    message.destroy()
                }
            }
        },
        selectData:{
            handler:function(val,oldVal){
                this.onChangePicker()
            },
            deep:true
        }
    },
    data(){
        return{
            SelectArea:[0,0],
            selectData:{
                startDate:null,
                startTime:null,
                endDate:null,
                endTime:null,
            },
            endOpen: false,
            is_exclude:"false",
            containment:"occurred-in",
            type:"timeframe",
            TimeRangeList:[],
            mark:{},
            nstep:0,
            RangeObj:[],
            TimeframeArr:[],
            windowWidth:window.innerWidth,
        }
    },
    mounted(){
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
        this.TimeRangeList = TimeRange(this.Timeframes.x_axis.min,this.Timeframes.x_axis.max,10)
        this.rangeList = TimeRange(this.Timeframes.x_axis.min,this.Timeframes.x_axis.max,20)
        this.GetTimeframes()

    },
    methods:{
        disabledStartDate(startValue) {
            const endValue = this.selectData.end;
            if(!endValue){
                return (moment(this.Timeframes.x_axis.min).valueOf() >= startValue.valueOf()) 
                || (moment(this.Timeframes.x_axis.max).valueOf() <= startValue.valueOf())
            }
            return startValue.valueOf() >= endValue.valueOf() || (moment(this.Timeframes.x_axis.min).valueOf() >= startValue.valueOf())
                   
        },
        disabledEndDate(endValue) {
            const startValue = this.selectData.start;
            if(!startValue){
                return (moment(this.Timeframes.x_axis.max).valueOf() <= endValue.valueOf())
                || (moment(this.Timeframes.x_axis.min).valueOf() >= endValue.valueOf())
            }
            return (startValue.valueOf() >= endValue.valueOf()) || (moment(this.Timeframes.x_axis.max).valueOf() <= endValue.valueOf())
        },
        handleStartOpenChange(open) {
            if (!open) {
                this.endOpen = true;
            }
        },
        handleEndOpenChange(open) {
          this.endOpen = open;
        },
        GetTimeframes(){
            this.TimeframeArr.push({
                date:moment(this.Timeframes.x_axis.min).format("YYYY-MM-DD HH:mm:ss"),
                value:this.Timeframes.data[0].y,
            })
            this.Timeframes.data.forEach((e,i)=>{
                this.TimeframeArr.push({
                    date:moment(e.x).format("YYYY-MM-DD HH:mm:ss"),
                    value:e.y,
                })
            })
            this.TimeframeArr.push({
                date:moment(this.Timeframes.x_axis.max).format("YYYY-MM-DD HH:mm:ss"),
                value:this.Timeframes.data[this.Timeframes.data.length-1].y,
            })
            this.getParam()
            this.onChangeSlider([0,100])
            //this.DrawRangePic()

        },
        getParam(){
            let nmark =
                this.TimeRangeList.length > 1 
                ? (100 / (this.TimeRangeList.length - 1)).toFixed(2)
                : 0;
            this.nstep = parseFloat((nmark / 2).toFixed(2));
            this.TimeRangeList.forEach((e,i)=>{
                let islast = this.TimeRangeList.length > 1 ? i === this.TimeRangeList.length - 1 : false;
                let markNum = (i * (nmark * 100)) / 100;
                this.mark[islast ? 100 : markNum] = {
                    /*label:{
                        tag:'span',
                        children:[{
                            tag:'label',
                            data:{
                                class:(islast)?'ml-[-5.5px]':''
                            },
                            text:moment(e).format("YY-MM-DD")
                            },
                            {
                                tag:'br'
                            },
                            {
                            tag:'label',
                            text:moment(e).format("HH:mm:ss")
                            },
                        ]
                    }*/
                    //`${moment(e).format("YY-MM-DD")}\n${moment(e).format("HH:mm:ss")}`,
                }
            })
            this.rangeList.forEach((e,i)=>{
                this.RangeObj.push({
                    val:this.nstep * i,
                    date:moment(e).format("YYYY-MM-DD HH:mm:ss")
                })
            })
        },
        onChange(value) {
        },
        onChangePicker() {
            /*var start = this.SelectArea[0] == 0 ?0:this.SelectArea[0]
            var complete = this.SelectArea[1] == 100 ?100:this.SelectArea[1]
            this.RangeObj.forEach((e)=>{
                if(this.selectData.startDate != null && this.selectData.startDate.isBefore(e.date)){
                    start = e.val
                }
                if(this.selectData.endDate != null && this.selectData.endDate.isAfter(e.date)){
                    complete = e.val
                }
            })
            this.$set(this.SelectArea, 0, start)
            this.$set(this.SelectArea, 1, complete)
            this.DrawRangePic()*/
        },
        onChangeSlider(value) {
          this.$set(this.SelectArea, 0, value[0])
          this.$set(this.SelectArea, 1, value[1])
          this.RangeObj.forEach((e)=>{
              if(this.SelectArea[0].toFixed(1) == e.val.toFixed(1)){
                  this.selectData.startDate = moment(e.date)
                  this.selectData.startTime = moment(e.date)
              }
              if(this.SelectArea[1].toFixed(1) == e.val.toFixed(1)){
                  this.selectData.endDate = moment(e.date)
                  this.selectData.endTime = moment(e.date)
              }
          })
          this.DrawRangePic()
          //this.selectData.startDate = this.RangeObj
        },
        DrawRangePic(){
            d3.select("#RangePic").selectAll("svg").remove();
            TimeAreaChart(this.TimeframeArr,'RangePic',this.SelectArea,this.windowWidth)
        },
        onResize() {
            this.windowWidth = window.innerWidth
            this.DrawRangePic()
        },
        submit(){
            //if(this.selectData.startDate == null ||this.selectData.startTime == null || this.selectData.endDate == null ||this.selectData.endTime == null)return
            message.loading('Filtering ...',1)
            var Dateobj = {
                start : this.selectData.startDate.format("YYYY-MM-DD") + " "+ this.selectData.startTime.format("HH:mm:ss"),
                end: this.selectData.endDate.format("YYYY-MM-DD") + " "+ this.selectData.endTime.format("HH:mm:ss"),
            }
            var obj = {
                type: this.containment,
                start: moment(Dateobj.start).format("YYYY-MM-DDTHH:mm:ss.SSS"),
                end: moment(Dateobj.end).format("YYYY-MM-DDTHH:mm:ss.SSS"),
                is_exclude: this.is_exclude == "true"
            }
            this.$store.dispatch('Filter/set_tempFilter',obj)
            this.$EventBus.$emit("RegetFunnel")
            message.success('Filter Success')
        }
    }
}
</script>