import Vue from 'vue';
import Vuex from 'vuex'
import VueResource from 'vue-resource';
import moment from 'moment';
Vue.use(VueResource);
Vue.use(Vuex)
function init_Analyze(){
    return{
        Data:{},
        fetchStatus:'none',
    }
}
const state = init_Analyze();
const getters = {
    get_AllAnalyze: state => {
        return state.Data;
    },
    get_Analyze_status: state => {
        return state.fetchStatus;
    },
}
const actions = {
    /**
     * fetch analyze api
     * @param {array} arr array of an Object , contains { log_id:number } or { log_id:number, filter_id:number }
     */
    fetch_Analyze({ state, commit },arr) {
        if (state.fetchStatus === 'fetching') return
        commit('set_data_fetch_status', 'fetching')
        var data = JSON.stringify(arr)
        Vue.http
            .get(`/api/performance`,{params: 
                {
                    datasets: data,
                }
            })
            .then(res => {
                commit('Set_Analyze', JSON.parse(JSON.stringify(res.body)));
                commit('set_data_fetch_status', 'finish')
            })
    },
    set_data_fetch_status({ state, commit }){
        commit("set_data_fetch_status",'none')
    }
}
const mutations = {
    Set_Analyze(state, Analyze) {
        state.Data = Analyze;
    },
    set_data_fetch_status(state, status) {
        state.fetchStatus = status;
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  