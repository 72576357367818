<template>
    <div class="w-[95%] h-full flex flex-col">
        <a-button block :ghost="name == 'Sequence'" class="m-2" :type="name == 'Sequence'?'primary':''" @click="emitFilter('Sequence')">
            Sequence
        </a-button>
        <!--
        目前尚未使用Filter - Attribute功能
        <a-button block :ghost="name == 'Attributes'" class="m-2" :type="name == 'Attributes'?'primary':''" @click="emitFilter('Attributes')">
            Attributes
        </a-button>-->
        <a-button block :ghost="name == 'Timeframes'" class="m-2" :type="name == 'Timeframes'?'primary':''" @click="emitFilter('Timeframes')">
            Timeframes
        </a-button>
        <a-button block :ghost="name == 'Traces'" class="m-2" :type="name == 'Traces'?'primary':''" @click="emitFilter('Traces')">
            Traces
        </a-button>
    </div>
</template>
<script>

export default {
    data(){
        return{
            name:"Sequence"
        }
    },
    methods:{
        /**
         * 進入不同的filter
         * @param {string} name Sequence / Attributes / Timeframes / Traces
         */
        emitFilter(name){
            this.name = name
            this.$EventBus.$emit("SetFilter",name)
        }
    }
}
</script>