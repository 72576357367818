import Vue from 'vue';
import Vuex from 'vuex'
import VueResource from 'vue-resource';
Vue.use(VueResource);
Vue.use(Vuex)
function init_Funnel(){
    return{
        Data:{},
        funnelStatus:'none',
    }
}
const state = init_Funnel();
const getters = {
    get_AllFunnel: state => {
        return state.Data;
    },
    get_funnelStatus: state => {
        return state.funnelStatus;
    },
}
const actions = {
    /**
     * fetch funnel api
     */
    fetch_Funnel({ state, commit }) {
        //判斷是否有filter 才決定要不要fetch funnel資料
        if(localStorage.filter_id == undefined || localStorage.filter_id == "undefined")return
        if (state.funnelStatus === 'fetching') return
        commit('set_funnelStatus', 'fetching')
        Vue.http
            .get("/api/event-logs/" + localStorage.Log_id +"/filters/"+ localStorage.filter_id)
            .then(res => {
                commit('Set_Funnel', JSON.parse(JSON.stringify(res.body)));
                commit('set_funnelStatus', 'finish')
            })
    },
    set_funnelStatus_none({state,commit}){
        commit('set_funnelStatus', 'none')
    }
}
const mutations = {
    Set_Funnel(state, Funnel) {
        state.Data = Funnel;
    },
    set_funnelStatus(state, status) {
        state.funnelStatus = status;
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  