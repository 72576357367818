// 
import Vue from 'vue';
import Vuex from 'vuex'
import VueResource from 'vue-resource';
import moment from 'moment';
Vue.use(VueResource);
Vue.use(Vuex)
function init_Files(){
    return{
        Data:{},
    }
}
const state = init_Files();
const getters = {
    get_AllFiles: state => {
        return state.Data;
    },
}
const actions = {
    /**
     * fetch Files api
     */
    fetch_Files({ state, commit }) {
        Vue.http
            .get("/api/event-logs")
            .then(res => {
                commit('Set_Files', JSON.parse(JSON.stringify(res.body)));
            })
    },
    //目前尚未使用修改檔案名稱功能
    /*Set_Files_name({ state, commit },obj) {
        Vue.http
            .put("/api/event-logs/" + obj.id,JSON.stringify({name:obj.name}))
            .then(res => {
                //res.body.is_success == true
            })
    }*/
}
const mutations = {
    Set_Files(state, Files) {
        Files.forEach(e => {
            e.created_at = moment(e.created_at).format('YYYY-MM-DD HH:mm:ss')
        });
        state.Data = Files;
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  