<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
        :fill="active?'#66b2ff':'white'"
        d="M7 22.75H2.5V29.5H7V22.75ZM18.25 13.75H13.75V29.5H18.25V13.75ZM29.5 2.5V29.5H25V2.5H29.5ZM25 0.25C24.4033 0.25 23.831 0.487053 23.409 0.90901C22.9871 1.33097 22.75 1.90326 22.75 2.5V29.5C22.75 30.0967 22.9871 30.669 23.409 31.091C23.831 31.5129 24.4033 31.75 25 31.75H29.5C30.0967 31.75 30.669 31.5129 31.091 31.091C31.5129 30.669 31.75 30.0967 31.75 29.5V2.5C31.75 1.90326 31.5129 1.33097 31.091 0.90901C30.669 0.487053 30.0967 0.25 29.5 0.25H25ZM11.5 13.75C11.5 13.1533 11.7371 12.581 12.159 12.159C12.581 11.7371 13.1533 11.5 13.75 11.5H18.25C18.8467 11.5 19.419 11.7371 19.841 12.159C20.2629 12.581 20.5 13.1533 20.5 13.75V29.5C20.5 30.0967 20.2629 30.669 19.841 31.091C19.419 31.5129 18.8467 31.75 18.25 31.75H13.75C13.1533 31.75 12.581 31.5129 12.159 31.091C11.7371 30.669 11.5 30.0967 11.5 29.5V13.75ZM0.25 22.75C0.25 22.1533 0.487053 21.581 0.90901 21.159C1.33097 20.7371 1.90326 20.5 2.5 20.5H7C7.59674 20.5 8.16903 20.7371 8.59099 21.159C9.01295 21.581 9.25 22.1533 9.25 22.75V29.5C9.25 30.0967 9.01295 30.669 8.59099 31.091C8.16903 31.5129 7.59674 31.75 7 31.75H2.5C1.90326 31.75 1.33097 31.5129 0.90901 31.091C0.487053 30.669 0.25 30.0967 0.25 29.5V22.75Z"/>
    </svg>
</template>
<script>
export default {
  props: {
    active: Boolean
  }
};
</script>
