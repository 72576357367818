<!--
目前尚未使用Filter - Attribute功能
-->
<template>
    <div class="h-full w-[97%] flex flex-col ml-2 mt-4 text-[#61696F]">
        <div class="h-1/5 w-full flex">
            <div class="my-2 border border-[#66b2ff] flex flex-col w-1/6 rounded">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-2 leading-8">Type</span>
                </div>
                <div class="flex flex-col my-1 ml-2">
                    <a-radio-group v-model="Attributes.selected_data_type" @change="ChangeSelectType" class="flex flex-col w-full">
                        <a-radio  value="case_attribute">
                            <span>Case</span>
                        </a-radio>
                        <a-radio  value="activity_attribute">
                            <span>Activity</span>
                        </a-radio>
                    </a-radio-group>
                </div>

            </div>
            <div class="ml-4 my-2 border border-[#66b2ff] flex flex-col w-1/6 rounded">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-2 leading-8">Refine</span>
                </div>
                <div class="flex flex-col my-1 ml-2">
                    <a-radio-group v-model="SelectData.refine" @change="onChange" class="flex flex-col w-full">
                        <a-radio  value="include">
                            <span>Include</span>
                        </a-radio>
                        <a-radio  value="exclude">
                            <span>Exclude</span>
                        </a-radio>
                    </a-radio-group>
                </div>

            </div>
        </div>
        <div class="h-[70%] w-full flex">
            <div class="my-2 border border-[#66b2ff] flex flex-col h-full rounded w-1/3">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-4 leading-8">Attribute Name</span>
                </div>
                <div class="flex flex-col my-1 ml-4">
                    <!--<a-input-search placeholder="input search text" style="width: 200px" @search="onSearch" />-->
                    <a-table
                        class="mr-4 mt-2"
                        :row-selection="{ selectedRowKeys: selectedAttrKeys, onChange: onSelectChange,type:'radio'}"
                        :columns="AttributeName"
                        :data-source="AttributeList"
                        :scroll="{ y: 280 }"
                        size="middle"
                    />
                </div>
            </div>

            <div class="my-2 border border-[#66b2ff] flex flex-col h-full rounded ml-4 w-2/3" v-show="Attributes.selected_enum == true && FetchStatus === 'finish'">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-4 leading-8">Value List</span>
                </div>
                <div class="flex flex-col my-1 ml-4">
                    <a-table
                        class="mr-4 mt-2"
                        :row-selection="{ selectedRowKeys: selectedValueKeys, onChange: onSelectValueChange,type:'checkbox'}"
                        :columns="ValueName"
                        :data-source="ValueList"
                        :scroll="{ y: 280 }"
                        size="middle"
                    />
                </div>
            </div>
            <div class="flex w-2/3" v-show="Attributes.selected_enum == false && FetchStatus === 'finish'">
                <div class="my-2 border border-[#66b2ff] flex flex-col h-full rounded ml-4 w-1/2">
                    <div class="border-b border-[#66b2ff]">
                        <span class="text-[#66b2ff] ml-4 leading-8">Range selection</span>
                    </div>
                    <div class="mx-2" id="RangePic"></div>
                    <a-slider range :value="[SeletArea[0], SeletArea[1]]" :min="MaxMinVal.Min" :max="MaxMinVal.Max" class="w-[80%] ml-14" @change="onChangeSlider"/>
                    <div class="flex items-center ml-8">
                        Range:from
                        <a-input-number v-model="SeletArea[0]" :min="MaxMinVal.Min" :max="MaxMinVal.Max" class="mx-2"/>
                        to
                        <a-input-number v-model="SeletArea[1]" :min="MaxMinVal.Min" :max="MaxMinVal.Max" class="mx-2"/>
                    </div>
                </div>
                <div class="my-2 border border-[#66b2ff] flex flex-col h-full rounded ml-4 w-1/2">
                    <div class="border-b border-[#66b2ff]">
                        <span class="text-[#66b2ff] ml-4 leading-8">Range result</span>
                    </div>
                    <div class="flex flex-col m1-2 ml-4">
                        <a-table
                            class="mr-4 mt-2"
                            :columns="ValueName"
                            :data-source="ValueList"
                            :scroll="{ y: 280 }"
                            size="middle"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 h-[10%] w-full flex justify-end">
            <a-button ghost type="primary" class="mr-2">Reset</a-button>
            <a-button ghost type="primary" class="" @click="submit">OK</a-button>
        </div>
    </div>
</template>
<script>
import * as d3 from "d3";
import AreaChart from "../../../module/AreaChart.js"
import { mapGetters,mapActions } from 'vuex';
import { message } from 'ant-design-vue';
export default {
    computed:{
        ...mapGetters({
            Attributes: "Attributes/get_AllAttributes",
            Attributes_DefaultData_Array:"Attributes/get_Attributes_DefaultData_Array",
            FetchStatus:"Attributes/get_AttrFetchStatus",
            MaxMinVal:"Attributes/get_MaxMinVal"
        }),
    },
    watch:{
        SeletArea:{
            handler(){
                this.DrawRangePic()
                this.SelectData.lower_bound = this.SeletArea[0]
                this.SelectData.upper_bound = this.SeletArea[1]
            },
            deep:true
        },
        FetchStatus:{
            handler:function(val,oldVal){
                message.loading('loading ...',0) 
                if(val === 'finish'){
                    this.GetAttribute()
                    message.destroy()
                }
            }
        },
    },
    data(){
        return{
            AttributeName:[
                {
                    title: 'List',
                    dataIndex: 'AttributeName',
                    key:'key'
                },
            ],
            ValueName:[
                {
                    title:'Value',
                    dataIndex: 'ValueName',
                },
                {
                    title:'Occurrences',
                    dataIndex: 'Occurrences',
                },
            ],
            AttributeList:[],
            ValueList:[],
            SeletArea:[2,3],
            enum:true,
            SelectData:{
                column:"",
                types:"",
                lower_bound:null,
                upper_bound:null,
                bound_type:"",
                lower_bound_comp:null,
                upper_bound_comp:null,
                containment:null,
                refine:"include",
        },
            selectedAttrKeys: [],
            selectedValueKeys:[]
        }
    },
    mounted(){
        this.$store.dispatch('Attributes/set_data_fetch_status')
        this.$store.dispatch('Attributes/fetch_Attributes')
    },
    methods:{
        GetAttribute(){
            //enum true str
            if(this.Attributes.selected_enum){
                this.SelectData.lower_bound_comp = "in"
                this.SelectData.upper_bound_comp = null
                this.SelectData.lower_bound = null
                this.SelectData.upper_bound = null
                this.SelectData.bound_type = "str"
            }
            else{
                this.$set(this.SeletArea, 0, this.MaxMinVal.Min)
                this.$set(this.SeletArea, 1, this.MaxMinVal.Max)

                this.DrawRangePic()
                this.SelectData.lower_bound_comp = "geq"
                this.SelectData.upper_bound_comp = "leq"
                this.SelectData.lower_bound = this.SeletArea[0]
                this.SelectData.upper_bound = this.SeletArea[1]
                this.SelectData.bound_type = "int"
            }
            this.setAttributeList()
            this.setValueList(this.SeletArea[0],this.SeletArea[1])

        },
        onChange(value) {
        },
        ChangeSelectType(){
            const selecttype = this.Attributes.selected_data_type.split("_")
            const opt = {
                types:selecttype[0],
                selected:""
            }
            this.$store.dispatch('Attributes/set_data_fetch_status')
            this.$store.dispatch('Attributes/fetch_Attributes',opt)
        },
        onChangeSlider(value) {
          this.$set(this.SeletArea, 0, value[0])
          this.$set(this.SeletArea, 1, value[1])
          this.setValueList()
        },
        onSearch(value) {
            console.log(value);
        },
        start() {
            setTimeout(() => {
                this.loading = false;
                this.selectedRowKeys = [];
            }, 1000);
        },
        //change radio
        onSelectChange(selectedRowKeys) {
            this.selectedAttrKeys = []
            this.selectedAttrKeys.push(selectedRowKeys[0]);
            var col = ""
            this.Attributes[this.Attributes.selected_data_type].forEach((e,i)=>{
                if(this.selectedAttrKeys[0] == i){
                    col = e
                }
            })
            this.$store.dispatch('Attributes/set_data_fetch_status')
            const selecttype = this.Attributes.selected_data_type.split("_")
            const opt = {
                types:selecttype[0],
                selected:col
            }
            this.$store.dispatch('Attributes/fetch_Attributes',opt)
        },
        //change checkbox
        onSelectValueChange(selectedRowKeys) {
            this.selectedValueKeys = []
            this.selectedValueKeys = selectedRowKeys
        },
        DrawRangePic(){
            d3.select("#RangePic").selectAll("svg").remove();
            AreaChart(this.Attributes_DefaultData_Array,'RangePic',this.SeletArea)
        },
        setAttributeList(){
            this.selectedAttrKeys = []
            this.AttributeList = []
            this.SelectData.column = this.Attributes.selected_attribute
            const type = this.Attributes.selected_data_type.split("_")
            this.SelectData.types = type[0]
            this.Attributes[this.Attributes.selected_data_type].forEach((e,i)=>{
                if(e === this.Attributes.selected_attribute){
                    this.selectedAttrKeys.push(i)
                }
                this.AttributeList.push({
                    key: i,
                    AttributeName: e,
                });
            })
        },
        setValueList(){
            this.selectedValueKeys = []
            this.ValueList = []
            if(this.Attributes.selected_enum == false){
                this.Attributes_DefaultData_Array.forEach((e,i)=>{
                    if(this.SeletArea[0] <= e.key && this.SeletArea[1] >= e.key){
                        this.ValueList.push({
                            key:e.key,
                            ValueName: e.key,
                            Occurrences:e.value,
                        });
                    }
                })
            }
            else{
                this.Attributes_DefaultData_Array.forEach((e,i)=>{
                    this.ValueList.push({
                        key:e.key,
                        ValueName: e.key,
                        Occurrences:e.value,
                    });
                })
            }
        },
        submit(){
            if(this.Attributes.selected_enum){
                this.SelectData.lower_bound = this.selectedValueKeys.toString()
            }
            if(this.SelectData.lower_bound == "")return
            message.loading('Filtering ...',0)
            var data = {
                prefix:"kmu",
                filter:this.SelectData
            }
            const selecttype = this.Attributes.selected_data_type.split("_")
            const opt = {
                types:selecttype[0],
                selected:this.SelectData.column
            }
            this.$http
                .post("/api/filter/set/",JSON.stringify(data))
                .then(res => {
                    message.destroy()
                    if(res.body.status == "200"){
                        message.success('Filter Success')
                        this.$EventBus.$emit("RegetFunnel")
                        this.$store.dispatch('Attributes/set_data_fetch_status')
                        this.$store.dispatch('Attributes/fetch_Attributes',opt)
                    }
                    else{
                        message.error('Fail')
                    }
                })
        }
    }
}
</script>