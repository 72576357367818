/**
 * 將秒數轉換成帶有時間單位的格式
 * @param   {number} Second 
 * @returns {string}        轉換完的格式(ex: 1 day, 6.8 hrs)
 */
export default function TimeLabel(Second) {
    var day = 24 * 60 * 60
    var hour = 60 * 60
    var minutes = 60
    var dd = Math.floor(Second / day)
    var hh = Math.floor((Second % day) / hour)
    var mm = Math.floor((Second % hour) / minutes)
    if(dd > 0){
        return dd + " days"
    }
    else if(hh > 0){
        return hh + " hrs"
    }
    else if(mm > 0){
        return mm + " mins"
    }
    if(Second == 0){
        return Second + " sec"
    }
    return Second + " secs"
}