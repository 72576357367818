<template>
    <div class="h-full w-[97%] flex flex-col ml-2 mt-4 text-[#61696F] block">
        <div class="h-1/5 w-full flex">
            <div class="my-2 border border-[#66b2ff] flex flex-col w-1/6 rounded">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-2 leading-8">Refine</span>
                </div>
                <div class="flex flex-col my-1 ml-2">
                    <!--
                        refine radio group
                    -->
                    <a-radio-group v-model="is_exclude" class="flex flex-col w-full">
                        <a-radio  value="false">
                            <span>Include</span>
                        </a-radio>
                        <a-radio  value="true">
                            <span>Exclude</span>
                        </a-radio>
                    </a-radio-group>
                </div>
            </div>
        </div>
        <div class="h-[70%] w-full flex">
            <div class="my-2 border border-[#66b2ff] flex flex-col h-full rounded w-1/2">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-4 leading-8">Range selection</span>
                    {{ this.Trace }}
                </div>
                <div class="mx-2 flex justify-center" id="TracePic"></div>
                <a-slider range v-model="SelectArea" :step="nstep" :min="nstep" :max="Trace.length" class="w-[80%] ml-14" @change="onChangeSlider"/>
            </div>
            <div class="my-2 border border-[#66b2ff] flex flex-col h-full rounded w-1/2 ml-4">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-4 leading-8">Trace list</span>
                </div>
                <a-table :columns="TracesName" :data-source="ValueList" :scroll="{ y: 260 }">
                    <span slot="customTitle">Trace ID ({{ValueList.length}})</span>
                    <span slot="Occurrences" slot-scope="text,record" class="flex items-center">
                        <div class="flex h-3 w-1/2">
                            <div :style="barWidth(record.ratio)" class="bg-[#66b2ff] border-[#66b2ff] border"></div>
                            <div :style="barEmptyWidth(record.ratio)" class="border-[#66b2ff] border"></div>
                        </div>
                        <span class="ml-4">{{record.count}}</span>
                        <span class="ml-4">{{record.ratio}}</span>
                    </span>
                    <span slot="Details" slot-scope="text,record">
                        <a-button ghost type="primary" @click="more(record.TracesName)">More</a-button>
                    </span>
                </a-table>
            </div>
        </div>

        <div class="mt-4 h-[10%] w-full flex justify-end">
            <a-button ghost type="primary" class="mr-2">Reset</a-button>
            <a-button ghost type="primary" class="" @click="submit">Apply</a-button>
        </div>
        <MorePage v-if="showMore" />
    </div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex';
import MorePage from "./more/index.vue"
import numberLabel from "../../../module/numberLabel.js"
import AreaChart from "../../../module/AreaChart.js"
import * as d3 from "d3";
import { message } from 'ant-design-vue';
export default {
    computed:{
        ...mapGetters({
            Trace: "Filter/get_Filter_trace",
            FetchStatus:"Filter/get_FilterFetchStatus",
        }),
    },
    components:{
        MorePage,
    },
    watch:{
        FetchStatus:{
            handler:function(val,oldVal){
                message.loading('loading ...',0)
                if(val == 'finish'){
                    this.onChangeSlider([1,this.Trace.length])
                    message.destroy()
                }
            }
        },
    },
    data(){
    return{
        TracesName:[
            {
                slots: { title: 'customTitle' },
                dataIndex: 'TracesName',
                width:'25%',
            },
            {
                title: 'Activity Occurrences',
                dataIndex: 'count',
                scopedSlots: { customRender: 'Occurrences' },
                width:'55%',
            },
            {
                title: 'Details',
                dataIndex: 'Details',
                scopedSlots: { customRender: 'Details' },
                width:'20%',
            },
        ],
        ValueList:[],
        showMore:false,
        SelectArea:[1,1],
        nstep:1,
        is_exclude:"false",
    }
    },
    created() {
        this.$EventBus.$on("closeMore",this.Morewindow)
    },
    mounted(){
        if(this.FetchStatus == 'finish')this.onChangeSlider([1,this.Trace.length])
    },
    methods:{
        /**
         * @param {number} percent
         */
        barWidth(percent){
            return "width:" + percent
        },
        barEmptyWidth(percent){
            return "width:" + (100 - parseInt(percent.substr(0,percent.length-1))).toString() + "%"
        },
        /**
         * 將數值轉換成百分比字串
         * @param   {number} val
         * @returns {string}     轉換完成的百分比字串
         */
        getPercentlabel(val){
            if(val * 100 == 100){
                return (val*100) + "%"
            }
            return (val*100).toFixed(1) + "%"
        },
        //設定table顯示資料
        setValueList(){
            this.ValueList = []
            this.Trace.forEach((e,i)=>{
                if(this.SelectArea[0] <= i+1 && this.SelectArea[1] >= i+1){
                    this.ValueList.push({
                        key:i,
                        TracesName: e.id,
                        count:numberLabel(e.count),
                        ratio:this.getPercentlabel(e.ratio),
                    });
                }
            })
        },
        //繪圖
        DrawRangePic(){
            d3.select("#TracePic").selectAll("svg").remove();
            const Arr = []
            this.Trace.forEach((e,i)=>{
                Arr.push({
                    key:e.id,
                    xlabel:e.ratio * 100,
                    value:e.count,
                })
            })
            AreaChart(Arr,'TracePic',this.SelectArea)
        },
        /**
         * @param {array} value slider變化的數值
         */
        onChangeSlider(value){
            this.$set(this.SelectArea, 0, value[0])
            this.$set(this.SelectArea, 1, value[1])
            this.DrawRangePic()
            this.setValueList()
        },
        //送出目前篩選範圍
        submit(){
            message.loading('Filtering ...',1)
            const obj = {
                type: "trace-freq",
                lower: this.SelectArea[0],
                upper: this.SelectArea[1],
                is_exclude: this.is_exclude == "true"
            }
            this.$store.dispatch('Filter/set_tempFilter',obj)
            this.$EventBus.$emit("RegetFunnel")
            message.success('Filter Success')

        },
        /**
         * @param {number} id 開啟該id的更多資訊
         */
        more(id){
            this.$store.dispatch('Filter/fetch_Trace_more',id)
            this.Morewindow()
        },
        Morewindow(){
            this.showMore = !this.showMore
            this.$EventBus.$emit("ShowCover")
        },
    }
}
</script>
