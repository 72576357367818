import Vue from 'vue'
import Vuex from 'vuex'
import VueResource from 'vue-resource';
import MapData from "./modules/MapData.js"
//import Attributes from "./modules/Attributes.js"
import Funnel from "./modules/Funnel.js"
import Files from "./modules/Files.js"
import EventLogInfo from "./modules/EventLogInfo.js"
import Filter from "./modules/Filter.js"
import Analyze from "./modules/Analyze.js"
Vue.use(VueResource);
Vue.use(Vuex)
const store = new Vuex.Store({
  modules:{
    MapData,
//    Attributes,
    Funnel,
    Files,
    EventLogInfo,
    Filter,
    Analyze
  }
})
export default store;