<template>
    <div class="w-full h-[96%] flex flex-col overflow-scroll mt-2">
        <a-icon type="loading" class="text-2xl mt-2" v-if="loading" />
        <a-steps direction="vertical" progress-dot :current="TempFunnelLabel.length" class="w-full">
            <a-step v-for="(e,i) in TempFunnelLabel" :key="i" class="ml-2 mt-2 w-[97%]">
                <div slot="title" class="flex w-[98%] justify-between items-center">
                    <span>{{TempFunnelLabel[i].type}}</span>
                    <!--
                        目前先不做刪除filter
                        <a-icon type="delete"  class="mr-2" @click="deleteFunnel(e.id)"/>
                    -->
                </div>
                <div class="text-[#61696F] flex w-[95%]" slot="description">
                    {{TempFunnelLabel[i].label}}
                    
                </div>
            </a-step>
        </a-steps>
    </div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex';
import { message } from 'ant-design-vue';
import moment from 'moment';
export default {
    computed:{
        ...mapGetters({
            Funnel: "Funnel/get_AllFunnel",
            FunnelStatus:"Funnel/get_funnelStatus"
        }),
    },
    watch:{
        FunnelStatus:{
            handler:function(val,oldVal){
                if(val == 'finish'){
                    this.loading = false
                    this.GetLabel()
                }
            }
        }
    },
    mounted(){
        //先檢查目前的本地資料是否有filter資料
        if(localStorage.tempFilter != 'undefined' &&
           localStorage.tempFilter != undefined
        ){
            this.getTempFunnel()
        }
        //若無資料則顯示空的
        if(localStorage.filter_id == undefined || localStorage.filter_id == "undefined"){
            this.loading = false
            return
        }
        if(this.FunnelStatus != 'finish'){
            this.$store.dispatch('Funnel/fetch_Funnel')
        }
        else{
            this.loading = false
        }
    },
    data(){
        return{
            TempFunnelLabel:[],
            FunnelLabel:[],
            loading:true,
        }
    },
    created(){
        this.$EventBus.$on("RegetFunnel",this.getTempFunnel)
    },
    methods:{
        //獲取本地funnel資料
        getTempFunnel() {
            var TempFunnel = JSON.parse(localStorage.tempFilter)
            this.TempFunnelLabel =[]
            TempFunnel.forEach((e)=>{
                var obj = this.setLabel(e)
                this.TempFunnelLabel.push(obj)
            })
        },
        //獲取api的funnel資料
        GetLabel(){
            if(this.isEmpty(this.Funnel))return
            //將api資料加入為本地資料
            this.Funnel.rules.forEach((e)=>{
                this.$store.dispatch('Filter/set_tempFilter',e)
            })
            this.getTempFunnel()
        },
        /**
         * 檢查該obj是否為空的
         * @param {object} obj 
         */
        isEmpty(obj) {
            // null and undefined are "empty"
            if (obj == null) return true;

            // Assume if it has a length property with a non-zero value
            // that that property is correct.
            if (obj.length > 0)    return false;
            if (obj.length === 0)  return true;

            // If it isn't an object at this point
            // it is empty, but it can't be anything *but* empty
            // Is it empty?  Depends on your application.
            if (typeof obj !== "object") return true;

            // Otherwise, does it have any properties of its own?
            // Note that this doesn't handle
            // toString and valueOf enumeration bugs in IE < 9
            for (var key in obj) {
                if (hasOwnProperty.call(obj, key)) return false;
            }

            return true;
        },
        //將funnel資料轉化為label
        setLabel(e){
            var label = ""
            var type = ""
            var includeStr = e.is_exclude?" Exclude ":" Include "
            switch(e.type){
                case "contains-task":
                    label = `${includeStr} ${e.task}`
                    type = "Sequence"
                    break
                case "starts-with":
                    label = `Start with ${e.task} ${includeStr}`
                    type = "Sequence"
                    break
                case "ends-with":
                    label = `End with ${e.task} ${includeStr}`
                    type = "Sequence"
                    break
                case "start-end":
                    label = `Start with ${e.starts_with}, End with ${e.ends_with} ${includeStr}`
                    type = "Sequence"
                    break
                case "directly-follows":
                    label = "Directly-follows "
                    for(let i = 0;i<e.task_seq.length;i++){
                        if(i == 0){
                            label = label + e.task_seq[i]
                        }
                        else{
                            label = label + " -> " + e.task_seq[i]
                        }
                    }
                    label = label + includeStr
                    type = "Sequence"
                    break
                case "eventually-follows":
                    label = "Eventually-follows "
                    for(let i = 0;i<e.task_seq.length;i++){
                        if(i == 0){
                            label = label + e.task_seq[i]
                        }
                        else{
                            label = label + " -> " + e.task_seq[i]
                        }
                    }
                    label = label + includeStr
                    type = "Sequence"
                    break
                case "occurred-in":
                case "started-in":
                case "completed-in":
                case "occurred-around":
                    label = `${e.type} from ${moment(e.start).format("YYYY-MM-DD HH:mm:ss")} to ${moment(e.end).format("YYYY-MM-DD HH:mm:ss")} ${includeStr}`
                    type = "Timeframe"
                    break
                case "trace-freq":
                    label = `from ${e.lower} to ${e.upper} ${includeStr}`
                    type = "Trace"
                    break
                default:
                    break
            }
            return {
                type:type,
                label:label,
            }
        }
    }
}
</script>
<style >
.ant-steps-item-content{
    width:90% !important;
}
.ant-steps-item-title{
    width:100%;
}
</style>