<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import store from './store'; // import
export default {
  store
}
</script>