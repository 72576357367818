<template>
  <div id="components-layout-demo-basic">
    <a-layout class="h-[100vh]">
        <a-layout-header>
          <Header />
        </a-layout-header>
        <a-layout>
          <!-- sidebar -->
          <div class="flex flex-col justify-around h-full max-w-2/5">
            <MenuSidebar />
          </div>
          <!-- content -->
          <a-layout-content class="h-[100%] w-full max-h-[100%] min-h-[100%] flex flex-col overflow-y-hidden items-end">
              <div class="relative flex w-full h-full items-center" :class="collapsed?'pb-[11.5rem]':''">
                <router-view name="container"/>
              </div>
              <div class="flex flex-col w-full"
                v-show="showInfo"
              >
                <div
                  class="flex text-[#66b2ff] bg-white bg-opacity-0 w-full justify-center items-start z-10"
                  :class="collapsed?'mt-[-13.5rem]':'mt-[-.75rem]'"
                >
                    <div class="w-16 h-3 items-center flex justify-center bg-[#EEEFEF]">
                        <a-icon
                            class="trigger"
                            :type="collapsed ? 'down' : 'up'"
                            @click="clickTrigger"
                        />
                    </div>
                </div>
                <div
                  class="flex w-full relative bg-[#EEEFEF] flex-col"
                  :class="collapsed?'h-[12.5rem]':'hidden'"
                >
                  <router-view name="bottomSidebar"/>
                </div>
              </div>
          </a-layout-content>
          <!-- cover -->
          <div class="absolute h-[90%] w-full z-10 bg-gray-300 opacity-30" v-if="cover"></div>
          <EnterName v-if="EnterName" />
        </a-layout>
      </a-layout>
    </div>
</template>
<script>
import Header from "./header/index.vue"
import MenuSidebar from "./MenuSidebar/index.vue"
import EnterName from "../components/enterName.vue"
export default {
  components:{
    Header,
    MenuSidebar,
    EnterName
  },
  watch:{
    '$route'(to,from){
      if(to.name != "Discover"){
        this.collapsed = false
        this.showInfo = false
      }
      else{
        this.collapsed = true
        this.showInfo = true
      }
    }
  },
  created() {
    //save vuex data
    window.addEventListener("beforeunload",()=>{
      localStorage.setItem("Store",JSON.stringify(this.$store.state))
    })
    if(localStorage.getItem("Store"))this.$store.replaceState(Object.assign(this.$store.state,JSON.parse(localStorage.getItem("Store"))))

    //open / close cover
    this.$EventBus.$on("ShowCover",this.coverWindow)
    //save / save as filter
    this.$EventBus.$on("setName",this.setFilterName)
    this.$EventBus.$on("returnEnterName",this.returnEnterName)
    //open / close entername
    this.$EventBus.$on("closeEntername",this.showEnterName)

  },
  beforeMount() {
    if(this.$route.query.Log_id != null){
      localStorage.Log_id = this.$route.query.Log_id
    }
  },
  mounted(){
    if(localStorage.Log_id == null)this.$router.push('Files')
    if(this.$route.name != "Discover"){
      this.collapsed = false
      this.showInfo = false
    }
    else{
      this.collapsed = true
      this.showInfo = true
    }

  },
  data() {
    return {
      collapsed:true,
      showInfo:true,
      cover:false,
      EnterName:false,
      setNameType:"",
    };
  },
  methods:{
    clickTrigger(){
      this.collapsed = !this.collapsed;
    },
    setFilterName(type){
      this.setNameType = type
      this.showEnterName()
    },
    showEnterName(){
      this.EnterName = !this.EnterName
      this.coverWindow()
    },
    /**
     * set save / save as filter's name
     * @param {string} name
     */
    returnEnterName(name){
      if(this.setNameType == "SaveAsFilter"){
        this.$store.dispatch("Filter/Set_Filter",name)
        localStorage.filter_name = name
      }
      else if(this.setNameType == "SaveFilter"){
        this.$store.dispatch("Filter/Save_Filter",name)
        localStorage.filter_name = name
      }
      this.showEnterName()
    },
    coverWindow(){
      this.cover = !this.cover
    }
  }
};
</script>
<style>
#components-layout-demo-basic .ant-layout-header{
  height: auto;
  padding:0;
}
#components-layout-demo-basic .ant-layout-sider,
#components-layout-demo-basic .ant-layout-header {
  background: #fff;
  color: #000;
}
#components-layout-demo-basic .ant-layout-sider {
  background: #fff;
  color: #000;
}
#components-layout-demo-basic .ant-layout-content {
  background: #fff;
  color: #000;
  min-height: 80vh;
}
#components-layout-demo-basic > .ant-layout {
  margin-bottom: 48px;
}
#components-layout-demo-basic > .ant-layout:last-child {
  margin: 0;
}
</style>
