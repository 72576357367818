<template>
    <div class="flex flex-col h-[95%] w-full text-[#61696F] ml-4">
        <div class="border-b w-[98%]">
            <label>Files</label>
        </div>
        <div class="flex mt-4 w-full flex-wrap overflow-y-scroll">
            <div class="border flex flex-col w-[12rem] p-2 h-[10rem] cursor-pointer mr-4 m-2"
                v-for="(e,i) in AllEventLog"
                :key="i"
            　　 @dblclick="enterDiscover(e)"
            >
                <div class="flex w-full">
                    <div v-if="e.filter_id != undefined" class="flex w-full justify-between">
                        <FilterIcon />
                        <a-icon type="delete"  class="mr-2 text-lg" @click="DeleteEvenglogFilter(e)"/>
                    </div>
                    <DataIcon v-else/>
                    <!--
                    目前尚未使用修改檔案名稱功能
                    <div v-else class="flex w-full justify-between">
                        <DataIcon />
                        <a-icon type="edit"  class="mr-2 text-lg" @click="Editname(e)"/>
                    </div>
                    -->
                </div>
                <div class="h-[60%] mt-2 flex flex-col" v-if="e.filter_id != undefined">
                    <span class="font-bold">{{e.filter_name}}</span>
                    <span class="">{{e.name}}</span>
                </div>
                <span class="h-[60%] mt-2 font-bold" v-else>{{e.name}}</span>
                <div class="h-1/4 mt-2 flex flex-col justify-end">
                    <span>{{e.size}}</span>
                    <span>{{e.created_at}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DataIcon from "../../components/icon/dataIcon.vue"
import FilterIcon from "../../components/icon/FilterIcon.vue"
import { mapGetters,mapActions } from 'vuex';
import moment from 'moment';
import { message } from 'ant-design-vue';
export default {
    components:{
        DataIcon,
        FilterIcon
    },
    computed:{
        ...mapGetters({
            EventLog: "Files/get_AllFiles",
        }),
    },
    watch:{
        EventLog:{
            handler:function(val,oldVal){
                this.getEventLog()
            },
            deep:true,
        }
    },
    mounted(){
        this.$store.dispatch('Files/fetch_Files')
        //localStorage.Log_id = null
    },
    data(){
        return{
            AllEventLog:[],
        }
    },
    methods:{
        //讀取EventLog所需資料
        getEventLog(){
            this.AllEventLog = [] 
            this.EventLog.forEach((e)=>{
                //增加原檔案
                this.AllEventLog.push(e)
                //若有filter則增加filter資料
                if(e.filters.length != 0){
                    for(let i=0;i<e.filters.length;i++){
                        var obj={
                            name:e.name,
                            filter_name:e.filters[i].name,
                            id:e.id,
                            filter_id:e.filters[i].id,
                            created_at:moment(e.filters[i].created_at).format("YYYY-MM-DD HH:mm:ss"),
                        }
                        this.AllEventLog.push(obj)
                    }
                }
            })
        },
        /**
         * 刪除有filter的eventlog
         * @param {object} eventlog 
         */
        DeleteEvenglogFilter(eventlog) {
            let self = this
            this.$confirm({
                title: 'Do you want to delete this filter?',
                content: `When clicked the OK button, ${eventlog.filter_name} will be deleted .`,
                okText:"Delete",
                onOk() {
                    message.loading('loading ...',1)
                    self.$store.dispatch("Filter/delete_filter",eventlog)
                    setTimeout(()=>{
                        self.$store.dispatch("Files/fetch_Files")
                    },1000)
                },
                onCancel() {},
            });
        },
        Editname(eventlog){

        },
        /**
         * 選擇該eventLog 進入Discover頁面
         * @param {object} eventlog 
         */
        enterDiscover(eventlog){
            //set id
            localStorage.Log_id = eventlog.id
            localStorage.Log_name = eventlog.name
            if(eventlog.filter_id != undefined){
                localStorage.filter_id = eventlog.filter_id
                localStorage.filter_name = eventlog.filter_name
            }
            this.$router.push({path:'/Discover', query:{Log_id: localStorage.Log_id}})
        }
    }
}
</script>