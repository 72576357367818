import Vue from 'vue';
import Vuex from 'vuex'
import VueResource from 'vue-resource';
Vue.use(VueResource);
Vue.use(Vuex)
function init_Mapdata(){
    return{
        Data:{
            ProcessMap:{},
            BPMN:{},
        },
        fetchProcessMapStatus:'none',
        fetchBPMNStatus:'none',
    }
}
const state = init_Mapdata();
const getters = {
    get_AllMapdata: state => {
        return state.Data;
    },
    get_ProcessMapFetchStatus: state => {
        return state.fetchProcessMapStatus;
    },
    get_BPMNFetchStatus: state => {
        return state.fetchBPMNStatus;
    },
}
const actions = {
    /**
     * fetch processmap api
     */
    fetch_ProcessMap_data({ commit }){
        if (state.fetchProcessMapStatus === 'fetching') return
        commit('set_ProcessMap_fetch_status', 'fetching')
        var temp_filter_id = 0
        var p_filter,p1,p2
        var params = ""

        //先確認是否有temp_filter條件
        if(localStorage.tempFilter != undefined && localStorage.tempFilter != "undefined"){
            p_filter = Vue.http
                .post("/api/event-logs/" + localStorage.Log_id + "/filters/create-temp",JSON.parse(localStorage.tempFilter))
                .then(res => {
                    if(res.status == 200){
                        temp_filter_id = JSON.parse(JSON.stringify(res.body)).id
                    }
                })
                .catch(error => {
                    temp_filter_id = -1
                });
            //並確認該資料集是否有filter
            if(localStorage.filter_id != undefined && localStorage.filter_id != "undefined"){
                params = "&filter_id=" + localStorage.filter_id
            }
            //Get process-map api
            p_filter.then(()=>{
                if(temp_filter_id > 0){
                    Vue.http
                        .get("/api/event-logs/" + localStorage.Log_id + "/process-map?temp_filter_id=" + temp_filter_id + params)
                        .then(res => {
                            commit('Set_Mapdata_ProcessMap', JSON.parse(JSON.stringify(res.body)));
                            commit('set_ProcessMap_fetch_status', 'finish')
                        })
                        .catch(error => {
                            commit('set_ProcessMap_fetch_status', 'fail')
                        });
                }
                else if(temp_filter_id == -1){
                    commit('set_ProcessMap_fetch_status', 'fail')
                }
            })
        }
        else{
            //無temp filter條件則not use create-temp
            if(localStorage.filter_id != undefined&& localStorage.filter_id != "undefined"){
                params = "?filter_id=" + localStorage.filter_id
            }
            Vue.http
                .get("/api/event-logs/" + localStorage.Log_id + "/process-map"+ params)
                .then(res => {
                    commit('Set_Mapdata_ProcessMap', JSON.parse(JSON.stringify(res.body)));
                    commit('set_ProcessMap_fetch_status', 'finish')
                })
                .catch(error => {
                    commit('set_ProcessMap_fetch_status', 'fail')
                });
        }
    },
    /**
     * fetch bpmn api
     * @param {object} mapData { epsilon:float, eta:float }
     */
    fetch_BPMN_data({ commit },mapData){
        if (state.fetchBPMNStatus === 'fetching') return
        commit('set_BPMN_fetch_status', 'fetching')
        var temp_filter_id = 0
        var p_filter,p1,p2
        var params = ""
        if(localStorage.tempFilter != undefined && localStorage.tempFilter != "undefined"){
            p_filter = Vue.http
                .post("/api/event-logs/" + localStorage.Log_id + "/filters/create-temp",JSON.parse(localStorage.tempFilter))
                .then(res => {
                    if(res.status == 200){
                        temp_filter_id = JSON.parse(JSON.stringify(res.body)).id
                    }
                })
                .catch(error => {
                    temp_filter_id = -1
                });
            if(localStorage.filter_id != undefined && localStorage.filter_id != "undefined"){
                params = "&filter_id=" + localStorage.filter_id
            }
            p_filter.then(()=>{
                if(temp_filter_id > 0){
                    Vue.http
                        .get("/api/event-logs/" + localStorage.Log_id + "/bpmn?temp_filter_id=" + temp_filter_id+"&epsilon="+mapData.epsilon + "&eta=" + mapData.eta+ params)
                        .then(res => {
                            commit('Set_Mapdata_BPMN', JSON.parse(JSON.stringify(res.body)));
                            commit('set_BPMN_fetch_status', 'finish')
                        })
                        .catch(error => {
                            commit('set_BPMN_fetch_status', 'fail')
                        });
                }
                else if(temp_filter_id == -1){
                    commit('set_BPMN_fetch_status', 'fail')
                }
            })
        }
        else{
            //not use filter/create-temp
            if(localStorage.filter_id != undefined&& localStorage.filter_id != "undefined"){
                params = "&filter_id=" + localStorage.filter_id
            }
            Vue.http
                .get("/api/event-logs/" + localStorage.Log_id + "/bpmn?epsilon="+mapData.epsilon + "&eta=" + mapData.eta+ params)
                .then(res => {
                    commit('Set_Mapdata_BPMN', JSON.parse(JSON.stringify(res.body)));
                    commit('set_BPMN_fetch_status', 'finish')
                })
                .catch(error => {
                    commit('set_BPMN_fetch_status', 'fail')
                });
        }
    },
    set_ProcessMap_fetch_status({ commit }){
        commit('set_ProcessMap_fetch_status', 'none')
    },
    set_BPMN_fetch_status({ commit }){
        commit('set_BPMN_fetch_status', 'none')
    }
}
const mutations = {
    Set_Mapdata_ProcessMap(state, Mapdata) {
        state.Data.ProcessMap = JSON.parse(JSON.stringify(Mapdata));
    },
    Set_Mapdata_BPMN(state, Mapdata) {
        state.Data.BPMN = JSON.parse(JSON.stringify(Mapdata));
    },
    set_ProcessMap_fetch_status(state, status) {
        state.fetchProcessMapStatus = status;
    },
    set_BPMN_fetch_status(state, status) {
        state.fetchBPMNStatus = status;
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};