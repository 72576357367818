<template>
    <div class="h-full w-[98%] relative">
        <div v-if="emptyData">No Data.</div>
        <template v-else>
            <div id="ProcessMap" class="h-full w-full relative" v-show="Type == 'ProcessMap'"></div>
            <div id="BPMN" class="h-full w-full relative" v-show="Type == 'BPMN'"></div>
        </template>
    </div>
</template>

<script>
import ProcessMap from "../../module/ProcessMap.js"
import { mapGetters,mapActions } from 'vuex';
import { message } from 'ant-design-vue';
export default {
    computed:{
        ...mapGetters({
            mapData: "MapData/get_AllMapdata",
            FetchProcessMapStatus:"MapData/get_ProcessMapFetchStatus",
            FetchBPMNStatus:"MapData/get_BPMNFetchStatus",
        }),
    },
    data() {
        return {
            ProcessMap:{
                start_end:[],
                nodes:[],
                edges:[],
            },
            BPMN:{
                start_end:[],
                nodes:[],
                edges:[],
            },
            Type:"ProcessMap",
            Optval:{
                value:"total",
                key:"freq"
            },
            emptyData:false,
            Curvetype:'unbundled-bezier',
            Create_Map:{
                ProcessMap:false,
                BPMN:false,
            },
            //mapData:
        };
    },
    created(){
        //更改radio button
        this.$EventBus.$on("OptionValue",this.changeOpt)
        //更改 eta/epslion時觸發
        this.$EventBus.$on("GetNewMapdata",this.GetData)
        //切換bpmn/processmap時觸發
        this.$EventBus.$on("ChangeMap",this.changetype)
        //切換taxi/unbundled-bezier 時觸發
        this.$EventBus.$on("ChangeCurve",this.changeCurve)
    },
    mounted() {
        this.Create_Map.ProcessMap = false
        this.Create_Map.BPMN = false
        this.$store.dispatch('MapData/set_ProcessMap_fetch_status')
        this.$store.dispatch('MapData/set_BPMN_fetch_status')
        this.GetData()
    },
    beforeDestroy() {

    },
    watch:{
        FetchProcessMapStatus:{
            handler:function(val,oldVal){
                message.loading('loading ...',0)
                if(val === 'finish'){
                    this.createmap()
                    message.destroy()
                }
                //EmptyData
                else if(val === 'fail'){
                    this.emptyData = true
                    message.destroy()
                }
            }
        },
        FetchBPMNStatus:{
            handler:function(val,oldVal){
                message.loading('loading ...',0)
                if(val === 'finish'){
                    this.createmap()
                    message.destroy()
                }
                //EmptyData
                else if(val === 'fail'){
                    this.emptyData = true
                    message.destroy()
                }
            }
        },
    },
    methods:{
        //更改 eta/epslion時觸發
        /**
         * @param {object} mapData { epsilon:float, eta:float }
         */
        GetData(mapData={epsilon:0.8,eta:0.8}){
            if(this.Type == 'ProcessMap'){
                this.emptyData = false
                this.$store.dispatch('MapData/set_ProcessMap_fetch_status')
                this.$store.dispatch('MapData/fetch_ProcessMap_data')
            }
            else if(this.Type == 'BPMN'){
                this.emptyData = false
                this.$store.dispatch('MapData/set_BPMN_fetch_status')
                this.$store.dispatch('MapData/fetch_BPMN_data',mapData)
            }
        },
        //切換bpmn/processmap時觸發
        /**
         * @param {string} type ProcessMap/BPMN
         */
        changetype(type){
            this.Type = type
            console.log(this.Create_Map[this.Type]);
            if(!this.Create_Map[this.Type]){
                this.GetData()
            }
            else{
                this.createmap()
            }
        },
        //切換taxi/unbundled-bezier 時觸發
        /**
         * @param {string} type taxi/unbundled-bezier
         */
        changeCurve(type){
            this.Curvetype = type
            this.drawMap()
        },
        //更改radio button
        /**
         * @param {object} optobj {optvalue:string, optkey:string}
         */
        changeOpt(optobj){
            this.Optval.value = optobj.optvalue
            this.Optval.key = optobj.optkey
            this.drawMap()
        },
        //設定繪圖資料
        createmap(){
            if(this.Type == 'ProcessMap' && this.mapData.ProcessMap.vertices.length != 0 && !this.Create_Map[this.Type]){
                this.Create_Map[this.Type] = !this.Create_Map[this.Type]
                this.ProcessMap.start_end = this.AddnodesData("ProcessMap").start_end
                this.ProcessMap.nodes = this.AddnodesData("ProcessMap").nodes
                this.ProcessMap.edges = this.AddedgesData("ProcessMap")
                this.drawMap()
            }
            else if(this.Type == 'BPMN' && this.mapData.BPMN.vertices.length != 0 && !this.Create_Map[this.Type]){
                this.Create_Map[this.Type] = !this.Create_Map[this.Type]
                this.BPMN.start_end = this.AddnodesData("BPMN").start_end
                this.BPMN.nodes = this.AddnodesData("BPMN").nodes
                this.BPMN.edges = this.AddedgesData("BPMN")
                this.drawMap()
            }

        },
        //繪圖
        drawMap(){
            if(this.Type == 'ProcessMap' && this.mapData.ProcessMap.vertices.length != 0){
                const ProcessMapID = document.getElementById('ProcessMap')
                ProcessMap(this.ProcessMap.nodes,this.ProcessMap.edges,ProcessMapID,this.Optval.value,this.ProcessMap.start_end,this.Optval.key,this.Curvetype)
            }
            else if(this.Type == 'BPMN' && this.mapData.BPMN.vertices.length != 0){
                const BPMNID = document.getElementById('BPMN')
                ProcessMap(this.BPMN.nodes,this.BPMN.edges,BPMNID,this.Optval.value,this.BPMN.start_end,this.Optval.key,this.Curvetype)
            }
        },
        /**
         * 將node資料彙整
         * @param {string} type ProcessMap/BPMN
         */
        AddnodesData(type){
            //empty event freq & duration
            const event_freq={
                "total": "",
                "rel_freq": "",
                "average": "",
                "median": "",
                "max": "",
                "min": "",
                "cases": ""
            }
            const event_duration={
                "total": "",
                "rel_duration": "",
                "average": "",
                "median": "",
                "max": "",
                "min": "",
                "cases": ""
            }
            //gateway
            const gateway = {
                parallel:"+",
                exclusive:"x",
                inclusive:"o",
            }
            const start_end = []
            const nodes = []
            for(let i = 0;i<this.mapData[type].vertices.length;i++){
                //add gateway node
                if(this.mapData[type].vertices[i].type == "gateway"){
                    nodes.push({
                        data:{
                            label:gateway[this.mapData[type].vertices[i].gateway_type],
                            type:this.mapData[type].vertices[i].type,
                            id:this.mapData[type].vertices[i].id,
                            backgroundColor:'#FFF',
                            bordercolor:'#003366',
                            freq:event_freq,
                            duration:event_duration,
                            shape:"diamond",
                            height:60,
                            width:60
                        }
                    })
                }
                //add event node
                else if (this.mapData[type].vertices[i].type == "event"){
                    start_end.push(this.mapData[type].vertices[i].id)
                    nodes.push({
                        data:{
                            label:this.mapData[type].vertices[i].event_type,
                            id:this.mapData[type].vertices[i].id,
                            type:this.mapData[type].vertices[i].type,
                            backgroundColor:'#FFCCCC',
                            bordercolor:'#003366',
                            freq:event_freq,
                            duration:event_duration,
                            shape:"ellipse",
                            height:60,
                            width:60
                        }
                    })
                }
                //add node
                else{
                    nodes.push({
                        data:{
                            label:this.mapData[type].vertices[i].label,
                            type:this.mapData[type].vertices[i].type,
                            id:this.mapData[type].vertices[i].id,
                            backgroundColor:'#FFCCCC',
                            bordercolor:'#003366',
                            freq:this.mapData[type].vertices[i].freq,
                            duration:this.mapData[type].vertices[i].duration,
                            shape:"round-rectangle",
                            height:80,
                            width:100
                        }
                    })
                }
            }
            return {
                start_end:start_end,
                nodes:nodes
            }
        },
        /**
         * 將edge資料彙整
         * @param {string} type ProcessMap/BPMN
         */
        AddedgesData(type){
            //add event duration is empty
            const event_duration={
                "total": "",
                "rel_duration": "",
                "average": "",
                "median": "",
                "max": "",
                "min": "",
                "cases": ""
            }
            const edges = []
            for(let i = 0;i<this.mapData[type].edges.length;i++){
                edges.push({
                    data:{
                        source:this.mapData[type].edges[i].tail,
                        target:this.mapData[type].edges[i].head,
                        freq:this.mapData[type].edges[i].freq,
                        duration:this.mapData[type].edges[i].duration == null ? event_duration:this.mapData[type].edges[i].duration,
                        style:'dotted',
                        lineWidth:1,
                    }
                })
            }
            return edges
        },
    }

}
</script>
