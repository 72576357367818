/*
該module是Discover底下的Info資訊欄
*/
import Vue from 'vue';
import Vuex from 'vuex'
import VueResource from 'vue-resource';
Vue.use(VueResource);
Vue.use(Vuex)
function init_EventLogInfo(){
    return{
        Data:{},
        fetchInfoStatus:'none',
    }
}
const state = init_EventLogInfo();
const getters = {
    get_EventLogInfo: state => {
        return state.Data;
    },
    get_InfoFetchStatus: state => {
        return state.fetchInfoStatus;
    },
}
const actions = {
    /**
     * fetch EventLogInfo api
     */
    async fetch_EventLogInfo({ state, commit }) {
        if (state.fetchStatus === 'fetching') return
        commit('set_data_fetch_status', 'fetching')
        var temp_filter_id = 0
        var p_filter
        var params =""
        
        //先確認是否有temp_filter條件
        if(localStorage.tempFilter != undefined && localStorage.tempFilter != "undefined"){
            //get create-temp id
            p_filter = Vue.http
                .post("/api/event-logs/" + localStorage.Log_id + "/filters/create-temp",JSON.parse(localStorage.tempFilter))
                .then(res => {
                    if(res.status == 200){
                        temp_filter_id = JSON.parse(JSON.stringify(res.body)).id
                    }
                })
                .catch(error => {
                    temp_filter_id = -1
                });
            //並確認該資料集是否有filter
            if(localStorage.filter_id != undefined&& localStorage.filter_id != "undefined"){
                params = "&filter_id=" + localStorage.filter_id
            }
            //Get EventLogInfo api
            p_filter.then(()=>{
                //確認temp_filter_id 是否存在 若不存在則無該資料
                if(temp_filter_id > 0){
                    Vue.http
                        .get("/api/event-logs/" + localStorage.Log_id + "/stats?temp_filter_id="+temp_filter_id + params)
                        .then(res => {
                            commit('Set_EventLogInfo', JSON.parse(JSON.stringify(res.body)));
                            commit('set_data_fetch_status', 'finish')
                        })
                }
                else if(temp_filter_id == -1){
                    commit('set_data_fetch_status', 'fail')
                }

            })
        }
        //無temp filter條件則not use create-temp
        else{
          if(localStorage.filter_id != undefined&& localStorage.filter_id != "undefined"){
            params = "?filter_id=" + localStorage.filter_id
          }
          Vue.http
          .get("/api/event-logs/" + localStorage.Log_id + "/stats" + params)
          .then(res => {
              commit('Set_EventLogInfo', JSON.parse(JSON.stringify(res.body)));
              commit('set_data_fetch_status', 'finish')
          })
        }
    },
    set_data_fetch_status({ commit }){
        commit('set_data_fetch_status', 'none')
    }
}
const mutations = {
    Set_EventLogInfo(state, EventLogInfo) {
        state.Data = EventLogInfo;
    },
    set_data_fetch_status(state, status) {
        state.fetchInfoStatus = status;
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  