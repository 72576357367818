<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
        :stroke="active?'#66b2ff':'white'"
        d="M16 1C7.7155 1 1 7.7155 1 16C1 24.2845 7.7155 31 16 31C24.2845 31 31 24.2845 31 16C31 7.7155 24.2845 1 16 1Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path 
        :stroke="active?'#66b2ff':'white'"
        d="M10 29.752V19L16 8.5L22 19V29.752" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path 
        :stroke="active?'#66b2ff':'white'"
        d="M10 19C10 19 11.6905 20.5 13 20.5C14.3095 20.5 16 19 16 19C16 19 17.6905 20.5 19 20.5C20.3095 20.5 22 19 22 19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>    
</template>
<script>
export default {
  props: {
    active: Boolean
  }
};
</script>