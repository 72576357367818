<!--
和files頁面大致相同
但有些功能上不同
目前是先分成兩個頁面（希望後續可以改寫成同一個）
-->
<template>
    <div class="flex flex-col h-[90%] w-[90%] text-[#61696F] ml-4 absolute z-20 bg-white border-2 p-2 overflow-y-scroll top-5 left-3">
        <div class="border-b w-full flex justify-between pl-2 mt-2">
            <label>Files</label>
            <a-icon type="close-circle" @click="close" theme="filled" class="mr-2" />
        </div>
        <div class="flex mt-4 w-full flex-wrap">
            <div class="border flex flex-col w-[12rem] p-2 h-[10rem] cursor-pointer mr-4 m-2"
                v-for="(e,i) in AllEventLog"
                :key="i"
            　　 @dblclick="SelectFile(e)"
            >
                <div class="flex w-full">
                    <div v-if="e.filter_id != undefined" class="flex w-full justify-between">
                        <FilterIcon />
                    </div>
                    <DataIcon v-else/>
                </div>
                <div class="h-[60%] mt-2 flex flex-col" v-if="e.filter_id != undefined">
                    <span class="font-bold">{{e.filter_name}}</span>
                    <span class="mt-2">{{e.name}}</span>
                </div>
                <span class="h-[60%] mt-2 font-bold" v-else>{{e.name}}</span>
                <div class="h-1/4 mt-2 flex flex-col justify-end">
                    <span>{{e.size}}</span>
                    <span>{{e.created_at}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DataIcon from "../../../components/icon/dataIcon.vue"
import FilterIcon from "../../../components/icon/FilterIcon.vue"
import { mapGetters,mapActions } from 'vuex';
import moment from 'moment';
import { message } from 'ant-design-vue';
export default {
    components:{
        DataIcon,
        FilterIcon
    },
    computed:{
        ...mapGetters({
            EventLog: "Files/get_AllFiles",
        }),
    },
    mounted(){
        this.getEventLog()
    },
    data(){
        return{
            AllEventLog:[],
        }
    },
    methods:{
        //讀取EventLog所需資料
        getEventLog(){
            this.AllEventLog = [] 
            this.EventLog.forEach((e)=>{
                //push原檔案
                this.AllEventLog.push(e)
                //若有filter則增加filter資料
                if(e.filters.length != 0){
                    for(let i=0;i<e.filters.length;i++){
                        var obj={
                            name:e.name,
                            filter_name:e.filters[i].name,
                            id:e.id,
                            filter_id:e.filters[i].id,
                            created_at:moment(e.filters[i].created_at).format("YYYY-MM-DD HH:mm:ss"),
                        }
                        this.AllEventLog.push(obj)
                    }
                }
            })
        },
        //選擇檔案
        /**
         * @param {object} obj an object of eventlog data
         */
        SelectFile(obj){
            var file
            //判斷該file是否有filter
            if(obj.filter_id != undefined){
                file = {
                    log_id:obj.id,
                    log_name:obj.name,
                    filter_id:obj.filter_id,
                    filter_name:obj.filter_name
                }
            }
            else{
                file = {
                    log_id:obj.id,
                    log_name:obj.name,
                }
            }
            this.$EventBus.$emit("AddFileData",file)
            this.close()
        },
        close(){
            this.$EventBus.$emit("ShowCover")
            this.$EventBus.$emit("AddFilePage")
        }
    }
}
</script>