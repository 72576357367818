import * as d3 from "d3";
import moment from 'moment';
/**
* this function will draw an AreaChart for Timeframe
* @param {array}  DefaultDataArr it contains Object { key:number, xlabel:number, value:number }
* @param {string} divID          Chart will be print on this DivID
* @param {array}  SelectArea     Chosen slider area
* @param {number} windowWidth    
*/
export default function TimeAreaChart(DefaultArr,divID,SelectArea,windowWidth) {
    // 定義svg的寬度和高度
    var width = windowWidth*0.6,
    height = 280,
    margin = {left:45,top:10,right:5,bottom:20},
    // 圖表的寬度=svg的寬度減去margin
    g_width = width - margin.left - margin.right,
    g_height = height - margin.top - margin.bottom,
    ydata = DefaultArr=>DefaultArr.value;
    const parseTime = d3.timeParse('%Y-%m-%d %I:%M:%S')

    var xdata = DefaultArr=>parseTime(DefaultArr.date)
    const X = d3.map(DefaultArr,xdata)

    const Y = d3.map(DefaultArr, ydata);
    const I = d3.range(X.length);

    let defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
    const D = d3.map(DefaultArr, defined);
    const xDomain = d3.extent(X)
    //d3.min(Y)
    const yDomain = [0, d3.max(Y)];
    const xScale = d3.scaleTime(xDomain, [margin.left,width-margin.right]);
    const yScale = d3.scaleLinear(yDomain, [height - margin.bottom, margin.top]);
    var xAxis
    if(parseInt(moment(xDomain[1]).valueOf() - moment(xDomain[0]).valueOf()) / 1000 / 60 /60 / 24 > 120){
        xAxis = d3.axisBottom(xScale).tickFormat(d3.timeFormat("%b"))
    }
    else{
        xAxis = d3.axisBottom(xScale)
    }
    //.tickValues(X).tickFormat(d3.timeFormat("%b"))
    //.tickFormat(d3.timeFormat("%Y-%m-%d %I:%M:%S"));//
    const yAxis = d3.axisLeft(yScale).ticks(height / 40);

    const area = d3.area()
      .defined(i => D[i])
      .x(i => xScale(X[i]))
      .y0(g_height + margin.top)
      .y1(i => yScale(Y[i]))
      .curve(d3.curveCardinal)
      ;

    const svg = d3.select("#"+divID)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
    
    /*svg.append("g")
        .attr("transform", `translate(${margin.left},0)`)
        .call(yAxis)
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll(".tick line").clone()
            .attr("x2", width - margin.left - margin.right)
            .attr("stroke-opacity", 0.1))*/
    
    var grad = svg.append("defs")
        .append("linearGradient")
        .attr("id", "grad");
    
    /*grad.append("stop").attr("offset", "0%").attr("stop-color", "#EEEFEF");    
    grad.append("stop").attr("offset", "100%").attr("stop-color", "#EEEFEF");*/
    grad.append("stop").attr("offset", "0%").attr("stop-color", "#EEEFEF");
    grad.append("stop").attr("offset", SelectArea[0]+"%").attr("stop-color", "#EEEFEF");

    grad.append("stop").attr("offset", SelectArea[0]+"%").attr("stop-color", "#5FACDD");
    grad.append("stop").attr("offset", SelectArea[1]+"%").attr("stop-color", "#5FACDD");

    grad.append("stop").attr("offset", SelectArea[1]+"%").attr("stop-color", "#EEEFEF");
    grad.append("stop").attr("offset", "100%").attr("stop-color", "#EEEFEF");
    

    svg.append("path")
        .attr("fill", "url(#grad)")
        .attr("d", area(I));
      
    svg.append("g")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(xAxis);
    
}