<template>
    <div class="h-full w-[97%] flex flex-col ml-2 mt-4 text-[#61696F]">
        <!-- header -->
        <div class="h-1/5 w-full flex">
            <div class="my-2 border border-[#66b2ff] flex flex-col w-2/6 rounded">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-2 leading-8">Activity Sequence</span>
                </div>
                <!--
                    select_sequence radio group
                -->
                <div class="flex flex-col my-1 ml-2">
                    <a-radio-group v-model="select_sequence" class="flex flex-col w-full">
                        <a-radio  value="contains-task">
                            <span>Have activity(s)</span>
                        </a-radio>
                        <a-radio  value="start_end">
                            <span>Start activity & end activity</span>
                        </a-radio>
                        <a-radio  value="sequence">
                            <span>Sequence</span>
                        </a-radio>
                    </a-radio-group>
                </div>

            </div>
            <!-- 僅在 select_sequence 選擇 start_end 可選 -->
            <div class="ml-4 my-2 border border-[#66b2ff] flex flex-col w-1/6 rounded" v-if="select_sequence == 'start_end'">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-2 leading-8">Start / End</span>
                </div>
                <!--
                    start_end radio group
                -->
                <div class="flex flex-col my-1 ml-2">
                    <a-radio-group v-model="start_end" class="flex flex-col w-full">
                        <a-radio  value="starts-with">
                            <span>Start</span>
                        </a-radio>
                        <a-radio  value="ends-with">
                            <span>End</span>
                        </a-radio>
                        <a-radio  value="start-end">
                            <span>Start & End</span>
                        </a-radio>
                    </a-radio-group>
                </div>
            </div>
            <!-- 僅在 select_sequence 選擇 sequence 可選 -->
            <div class="ml-4 my-2 border border-[#66b2ff] flex flex-col w-1/6 rounded" v-if="select_sequence =='sequence'">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-2 leading-8">Mode</span>
                </div>
                <!--
                    driectly / eventually follow radio group
                -->
                <div class="flex flex-col my-1 ml-2">
                    <a-radio-group v-model="mode" class="flex flex-col w-full">
                        <a-radio  value="directly-follows">
                            <span>Directly follows</span>
                        </a-radio>
                        <a-radio  value="eventually-follows">
                            <span>Eventually follows</span>
                        </a-radio>
                    </a-radio-group>
                </div>
            </div>

            <div class="ml-4 my-2 border border-[#66b2ff] flex flex-col w-1/6 rounded">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-2 leading-8">Refine</span>
                </div>
                <!--
                    refine radio group
                -->
                <div class="flex flex-col my-1 ml-2">
                    <a-radio-group v-model="is_exclude" class="flex flex-col w-full">
                        <a-radio  value="false">
                            <span>Include</span>
                        </a-radio>
                        <a-radio  value="true">
                            <span>Exclude</span>
                        </a-radio>
                    </a-radio-group>
                </div>
            </div>
        </div>
        <!-- container -->
        <!-- 僅在 select_sequence 選擇 contains-task 可選 -->
        <div class="h-[70%] w-full flex" v-if="select_sequence == 'contains-task'">
            <div class="my-2 border border-[#66b2ff] flex flex-col h-full rounded w-full">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-4 leading-8">Activity List</span>
                </div>
                <a-table
                    class="mx-2 mt-2"
                    :row-selection="{ selectedRowKeys: haveActivitykey, onChange: onSelectChange,type:'radio'}"
                    :columns="ValueName"
                    :data-source="haveActivityDataList"
                    :scroll="{ y: 'calc(50vh - 4em)' }"
                    size="middle"
                >
                    <span slot="customTitle">Activity List ({{haveActivityDataList.length}})</span>
                    <span slot="Occurrences" slot-scope="text,record" class="flex items-center">
                        <div class="flex h-3 w-1/2">
                            <div :style="barWidth(record.occurrence_ratio)" class="bg-[#66b2ff] border-[#66b2ff] border"></div>
                            <div :style="barEmptyWidth(record.occurrence_ratio)" class="border-[#66b2ff] border"></div>
                        </div>
                        <span class="ml-4">{{record.Occurrences}}</span>
                        <span class="ml-4">{{record.occurrence_ratio}}</span>
                    </span>
                    <span slot="CasesWithActivity" slot-scope="text,record" class="flex items-center">
                        <div class="flex h-3 w-1/2">
                            <div :style="barWidth(record.case_ratio)" class="bg-[#66b2ff] border-[#66b2ff] border"></div>
                            <div :style="barEmptyWidth(record.case_ratio)" class="border-[#66b2ff] border"></div>
                        </div>
                        <span class="ml-4">{{record.CasesWithActivity}}</span>
                        <span class="ml-4">{{record.case_ratio}}</span>
                    </span>
                </a-table>
            </div>
        </div>
        <!-- 僅在 select_sequence 選擇 start_end 可選 -->
        <div class="h-[70%] w-full flex" v-if="select_sequence == 'start_end'">
            <!-- 僅在 start_end 選擇 start-end 可選 -->
            <div class="my-2 border border-[#66b2ff] flex flex-col h-full rounded w-1/2" v-if="start_end == 'start-end'">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-4 leading-8">Start Activity</span>
                </div>
                <a-table
                    class="mx-2 mt-2"
                    :row-selection="{ selectedRowKeys: startValueKeys, onChange: startChange,type:'radio'}"
                    :columns="Start_EndValueName"
                    :data-source="start_endActivityDataList"
                    :scroll="{ y: 'calc(50vh - 4em)' }"
                    size="middle"
                >
                    <span slot="customTitle">Activity List ({{start_endActivityDataList.length}})</span>
                    <span slot="Occurrences" slot-scope="text,record" class="flex items-center">
                        <div class="flex h-3 w-1/3">
                            <div :style="barWidth(record.occurrence_ratio)" class="bg-[#66b2ff] border-[#66b2ff] border"></div>
                            <div :style="barEmptyWidth(record.occurrence_ratio)" class="border-[#66b2ff] border"></div>
                        </div>
                        <span class="ml-4">{{record.Occurrences}}</span>
                        <span class="ml-4">{{record.occurrence_ratio}}</span>
                    </span>
                </a-table>
            </div>
            <div class="my-2 border border-[#66b2ff] flex flex-col h-full rounded w-1/2 ml-4" v-if="start_end == 'start-end'">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-4 leading-8">End Activity</span>
                </div>
                <a-table
                    class="mx-2 mt-2"
                    :row-selection="{ selectedRowKeys: endValueKeys, onChange: endChange,type:'radio'}"
                    :columns="Start_EndValueName"
                    :data-source="end_startActivityDataList"
                    :scroll="{ y: 'calc(50vh - 4em)' }"
                    size="middle"
                >
                    <span slot="customTitle">Activity List ({{end_startActivityDataList.length}})</span>
                    <span slot="Occurrences" slot-scope="text,record" class="flex items-center">
                        <div class="flex h-3 w-1/3">
                            <div :style="barWidth(record.occurrence_ratio)" class="bg-[#66b2ff] border-[#66b2ff] border"></div>
                            <div :style="barEmptyWidth(record.occurrence_ratio)" class="border-[#66b2ff] border"></div>
                        </div>
                        <span class="ml-4">{{record.Occurrences}}</span>
                        <span class="ml-4">{{record.occurrence_ratio}}</span>
                    </span>
                </a-table>
            </div>
            <!-- 僅在 start_end 選擇 starts-with / ends-with 可選 -->
            <div class="my-2 border border-[#66b2ff] flex flex-col h-full rounded w-full" v-else>
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-4 leading-8">Activity List</span>
                </div>
                <a-table
                    class="mx-2 mt-2"
                    :row-selection="{ selectedRowKeys: haveActivitykey, onChange: onSelectChange,type:'radio'}"
                    :columns="Start_EndValueName"
                    :data-source="start_end == 'starts-with'?startActivityDataList:endActivityDataList"
                    :scroll="{ y: 280 }"
                    size="middle"
                >
                    <span slot="customTitle">Activity List ({{start_end == 'starts-with'?startActivityDataList.length:endActivityDataList.length}})</span>
                    <span slot="Occurrences" slot-scope="text,record" class="flex items-center">
                        <div class="flex h-3 w-1/2">
                            <div :style="barWidth(record.occurrence_ratio)" class="bg-[#66b2ff] border-[#66b2ff] border"></div>
                            <div :style="barEmptyWidth(record.occurrence_ratio)" class="border-[#66b2ff] border"></div>
                        </div>
                        <span class="ml-4">{{record.Occurrences}}</span>
                        <span class="ml-4">{{record.occurrence_ratio}}</span>
                    </span>
                </a-table>
            </div>
        </div>
        <!-- 僅在 select_sequence 選擇 sequence 可選 -->
        <div class="h-[70%] w-full flex" v-if="select_sequence == 'sequence'">
            <div class="my-2 border border-[#66b2ff] flex flex-col h-full rounded w-2/3">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-4 leading-8">Activity List</span>
                </div>
                <a-table
                    class="mx-2 mt-2"
                    :row-selection="{ selectedRowKeys: selectedValueKeys, onChange: sequenceChange,type:'radio'}"
                    :columns="Start_EndValueName"
                    :data-source="haveActivityDataList"
                    :scroll="{ y: 'calc(50vh - 4em)' }"
                    size="middle"
                >
                    <span slot="customTitle">Activity List ({{haveActivityDataList.length}})</span>
                    <span slot="Occurrences" slot-scope="text,record" class="flex items-center">
                        <div class="flex h-3 w-1/2">
                            <div :style="barWidth(record.occurrence_ratio)" class="bg-[#66b2ff] border-[#66b2ff] border"></div>
                            <div :style="barEmptyWidth(record.occurrence_ratio)" class="border-[#66b2ff] border"></div>
                        </div>
                        <span class="ml-4">{{record.Occurrences}}</span>
                        <span class="ml-4">{{record.occurrence_ratio}}</span>
                    </span>
                </a-table>
            </div>
            <div class="my-2 flex flex-col h-full justify-center">
                <a-icon type="right-square" class="mx-4 text-2xl cursor-pointer text-[#66b2ff]" @click="pushCol"/>
            </div>
            <div class="my-2 border border-[#66b2ff] flex flex-col h-full rounded w-1/3">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-4 leading-8">Activity Sequence</span>
                </div>
                <div class="h-[90%] block flex-col justify-center overflow-y-scroll">
                    <div class="mt-6 border rounded-3xl border-[#66b2ff] px-2 flex py-1 items-center mx-6" v-if="SequenceList.length > 0">
                        <Circleicon />
                        <span class="ml-2">{{SequenceList[0]}}</span>
                    </div>
                    <div class="flex flex-col justify-center" v-for="(e,i) in SequenceList" :key="i">
                        <a-icon class="text-[#66b2ff] text-2xl" type="arrow-down" v-if="i != 0" />
                        <div class="border rounded-3xl border-[#66b2ff] px-2 flex py-1 items-center mt-2 mx-6" v-if="i != 0">
                            <Circleicon />
                            <span class="ml-2">{{e}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 h-[10%] w-full flex justify-end">
            <a-button ghost type="primary" class="mr-2" @click="clearAllLists">Reset</a-button>
            <a-button ghost type="primary" class="" @click="submit">Apply</a-button>
        </div>
    </div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex';
import { message } from 'ant-design-vue';
import Circleicon from "../../../components/icon/circle.vue";
import numberLabel from "../../../module/numberLabel.js"
export default {
    computed:{
        ...mapGetters({
            ActivityList: "Filter/get_Filter_tasks",
            Start_EndList:"Filter/get_Filter_start_ends",
            End_StartList:"Filter/get_Filter_end_starts",
            FetchFilterStatus:"Filter/get_FilterFetchStatus",
        }),
    },
    components:{
      Circleicon
    },
    watch:{
        FetchFilterStatus:{
            handler:function(val,oldVal){
                message.loading('loading ...',0)
                if(val === 'finish'){
                    this.GetFilter()
                    message.destroy()
                }
            }
        },
    },
    data(){
        return{
            ValueName:[
                {
                    dataIndex: 'ValueName',
                    slots: { title: 'customTitle' },
                },
                {
                    title:'Activity Occurrences',
                    scopedSlots: { customRender: 'Occurrences' },
                    dataIndex: 'Occurrences',
                },
                {
                    title:'Cases with Activity',
                    dataIndex: 'CasesWithActivity',
                    scopedSlots: { customRender: 'CasesWithActivity' },
                },
            ],
            Start_EndValueName:[
                {
                    slots: { title: 'customTitle' },
                    dataIndex: 'ValueName',
                },
                {
                    title:'Activity Occurrences',
                    scopedSlots: { customRender: 'Occurrences' },
                    dataIndex: 'Occurrences',
                },
            ],
            //radio group
            start_end:"starts-with",
            select_sequence:"contains-task",
            mode:"directly-follows",
            is_exclude:"false",
            //activity table data
            haveActivityDataList:[],
            startActivityDataList:[],
            endActivityDataList:[],
            start_endActivityDataList:[],
            end_startActivityDataList:[],
            //sequence selected activity data
            selectedValueKeys:[],
            selectedValue:"",
            SequenceList:[],
            //selected activity data
            haveActivityList:[],
            haveActivitykey:[],
            startValue:"",
            startValueKeys:[],
            endValue:"",
            endValueKeys:[],
            //若第一次選擇start,則end連動改變，若第一次選擇end,則start連動改變
            start_endChange:null, //start true end false
        }
    },
    mounted(){
        this.$store.dispatch('Filter/set_Filter_fetch_status')
        this.$store.dispatch('Filter/fetch_Filter')
    },
    methods:{
        /**
         * @param {number} percent
         */
        barWidth(percent){
            return "width:" + percent
        },
        barEmptyWidth(percent){
            return "width:" + (100 - parseInt(percent.substr(0,percent.length-1))).toString() + "%"
        },
        //將activity資料匯入
        GetFilter(){
            this.sethaveActivityDataList()
            //set start_end
            this.start_endActivityDataList = this.setActivityDataList(this.Start_EndList)
            this.end_startActivityDataList = this.setActivityDataList(this.End_StartList)
            this.startActivityDataList = this.setActivityDataList(this.Start_EndList)
            this.endActivityDataList = this.setActivityDataList(this.End_StartList)
        },
        //設定haveActivityDataList內容
        sethaveActivityDataList(){
            this.haveActivityDataList = []
            for(let i = 0;i<this.ActivityList.length;i++){
                this.haveActivityDataList.push({
                    key:i,
                    ValueName: this.ActivityList[i].label,
                    Occurrences:numberLabel(this.ActivityList[i].occurrences),
                    occurrence_ratio:this.getPercentlabel(this.ActivityList[i].occurrence_ratio),
                    CasesWithActivity:numberLabel(this.ActivityList[i].cases),
                    case_ratio:this.getPercentlabel(this.ActivityList[i].case_ratio),
                });
            }
        },
        //設定start_endActivityDataList / end_startActivityDataList / startActivityDataList / endActivityDataList內容
        /**
         * @param {array} arr Start_EndList / End_StartList
         */
        setActivityDataList(arr){
            const list = []
            for(let i = 0;i<arr.length;i++){
                list.push({
                    key:i,
                    ValueName: arr[i].label,
                    Occurrences:numberLabel(arr[i].occurrences),
                    occurrence_ratio:this.getPercentlabel(arr[i].occurrence_ratio),
                });
            }
            return list
        },
        //若第一次選擇start,則end連動改變，若第一次選擇end,則start連動改變
        /**
         * @param {array} selectedRowKeys
         * @param {array} selectedRows an array of object, object contains selected row's data
         */
        startChange(selectedRowKeys,selectedRows) {
            console.log(selectedRowKeys,selectedRows)
            this.startValueKeys = selectedRowKeys
            this.startValue = selectedRows[0]
            if(this.start_endChange == null || this.start_endChange == true){
                this.end_startActivityDataList = this.setStart_endActivityDataList(this.Start_EndList,selectedRows[0].ValueName,"sinks")
                this.start_endChange = true
                this.endValue = ""
                this.endValueKeys = []
            }
        },
        endChange(selectedRowKeys,selectedRows) {
            this.endValueKeys = selectedRowKeys
            this.endValue = selectedRows[0]
            if(this.start_endChange == null || this.start_endChange == false){
                this.start_endActivityDataList = this.setStart_endActivityDataList(this.End_StartList,selectedRows[0].ValueName,"sources")
                this.start_endChange = false
                this.startValue = ""
                this.startValueKeys = []
            }
        },
        //設定start_endActivityDataList / end_startActivityDataList 內容
        /**
         * @param {object} obj  Start_EndList
         * @param {string} name 所選擇的activity name
         * @param {string} task sinks/sources
         */
        setStart_endActivityDataList(obj,name,task){
            const list = []
            for(let i = 0;i<obj.length;i++){
                if(obj[i].label == name){
                    for(let j = 0;j<obj[i][task].length;j++){
                        //start
                        if(task =="sinks"){
                            this.End_StartList.forEach(element => {
                                if(element.label == obj[i][task][j]){
                                    list.push({
                                        key:j,
                                        ValueName: obj[i][task][j],
                                        Occurrences:numberLabel(element.occurrences),
                                        occurrence_ratio:this.getPercentlabel(element.occurrence_ratio),
                                    });
                                }
                            });
                        }
                        //end
                        else{
                            this.Start_EndList.forEach(element => {
                                if(element.label == obj[i][task][j]){
                                    list.push({
                                        key:j,
                                        ValueName: obj[i][task][j],
                                        Occurrences:numberLabel(element.occurrences),
                                        occurrence_ratio:this.getPercentlabel(element.occurrence_ratio),
                                    });
                                }
                            });

                        }
                    }
                    console.log(list);
                    return list
                }
            }
        },
        /*
        尚未加入搜尋功能
        onSearch(value) {
            console.log(value);
        },*/
        //更改所選的activity
        /**
         * @param {array} selectedRowKeys
         * @param {array} selectedRows an array of object, object contains selected row's data
         */
        onSelectChange(selectedRowKeys,selectedRows) {
            this.haveActivitykey = selectedRowKeys
            this.haveActivityList = selectedRows[0]
        },
        sequenceChange(selectedRowKeys,selectedRows) {
            this.selectedValueKeys = selectedRowKeys
            this.selectedValue = selectedRows[0]
        },
        //將sequence 所選的data 新增至SequenceList
        pushCol(){
            if(this.selectedValue == "") return
            this.SequenceList.push(this.selectedValue.ValueName)
            this.clearLists()
        },
        clearLists(){
            this.haveActivitykey = []
            this.haveActivityList = []
            this.startValue = ""
            this.startValueKeys = []
            this.endValue = ""
            this.endValueKeys = []
            this.selectedValue = ""
            this.selectedValueKeys = []
            this.start_endChange = null
            this.start_endActivityDataList = this.setActivityDataList(this.Start_EndList)
            this.end_startActivityDataList = this.setActivityDataList(this.End_StartList)
        },
        clearAllLists(){
            this.clearLists()
            this.SequenceList = []
        },
        /**
         * 將數值轉換成百分比字串
         * @param   {number} val
         * @returns {string}     轉換完成的百分比字串
         */
        getPercentlabel(val){
            if(val * 100 == 100){
                return (val*100) + "%"
            }
            return (val*100).toFixed(1) + "%"
        },
        //將所選資料送出
        submit(){
            if(this.start_end == "start-end"){
                if(this.startValue.length == 0 || this.endValue.length == 0) return
            }
            else if(this.select_sequence == "sequence" && this.SequenceList.length < 2){
                return
            }
            else if((this.start_end == 'starts-with' || this.start_end == 'ends-with' || this.select_sequence == 'contains-task') && this.select_sequence != 'sequence'){
                if(this.haveActivityList.length == 0)return
            }

            message.loading('Filtering ...',1)
            var obj
            if(this.start_end == "start-end"){
                obj = {
                    type: this.select_sequence == "contains-task"?this.select_sequence:this.start_end,
                    starts_with:"",
                    ends_with:"",
                    is_exclude: this.is_exclude == "true"
                }
            }
            else if(this.select_sequence == "sequence"){
                obj = {
                    type: this.mode,
                    task_seq: this.SequenceList,
                    is_exclude: this.is_exclude == "true"
                }
            }
            else{
                obj = {
                    type: this.select_sequence == "contains-task"?this.select_sequence:this.start_end,
                    task: this.haveActivityList.ValueName,
                    is_exclude: this.is_exclude == "true"
                }
            }
            //start&end
            if(this.start_end == "start-end"){
                obj.starts_with = this.startValue.ValueName
                obj.ends_with = this.endValue.ValueName
                this.$store.dispatch('Filter/set_tempFilter',obj)
            }
            //sequence
            //have acticity
            //start&end start-with
            //start&end end-with
            else{
                this.$store.dispatch('Filter/set_tempFilter',obj)
            }
            this.clearAllLists()
            this.$EventBus.$emit("RegetFunnel")
            message.success('Filter Success')
        }
    }
}
</script>
