/**
 * 將date格式轉換成特定格式
 * @param   {date}   dt 
 * @returns {string} dataTime_str 特定格式時間(ex: "2017-10-05 01：02：56")
 */
function addDatetime(dt){
    dt = new Date(dt);
    var dataTime_str = "";

   var Year = dt.getFullYear();
   dataTime_str += Year + "-";//存入年

   var mon = parseInt(dt.getMonth()) + 1;
   dataTime_str += (mon < 10 ? ("0" + mon) : mon) + "-";//存入月  .getMonth()函数从0月开始算，正确日期应该+1
   
   var day = dt.getDate();
   dataTime_str += (day < 10 ? ("0" + day) : day) + " ";//存入日
   
   var hh_ = dt.getHours();
   dataTime_str += (hh_ < 10 ? ("0" + hh_) : hh_) + ":";//存入时
   
   var mm_ = dt.getMinutes();
   dataTime_str += (mm_ < 10 ? ("0" + mm_) : mm_) + ":";//存入分
   
   var ss_ = dt.getSeconds();
   dataTime_str += (ss_ < 10 ? ("0" + ss_) : ss_);//存入秒
   return dataTime_str;
}
/**
 * 將一段時間均分成多段的時間點
 * @param   {string} startTimes 開始時間(ex:2017-10-05)
 * @param   {string} endTimes   結束時間(ex:2017-10-06)
 * @param   {number} amount     切分成多少段
 * @returns {array}             均分成多段的時間array
 */
export default function TimeRange(startTimes, endTimes, amount){
    startTimes = Date.parse(new Date(startTimes)); // 计算开始时间的时间戳
    endTimes = Date.parse(new Date(endTimes)); // 计算结束时间的时间戳
    var timeAll = endTimes - startTimes; // 总时间间隔
    try {
        var timeRange = new Array(); // return数组初始化
        var timeGap = timeAll / amount ; // 分割后时间间隔

        //var slidingTime = 0;
        var momentTime_front = "";
        var momentTime_rear = "";
        
        for(var i = 0; i < amount; i++) {

            momentTime_front = addDatetime(startTimes + timeGap * (i  ));
            momentTime_rear = addDatetime(startTimes + timeGap * (i  +1) - 1000);
            //console.log(momentTime_front,momentTime_rear)
            timeRange.push(momentTime_front);
        }
        timeRange.push(momentTime_rear);
        return timeRange;
    } catch(e) {
        console.log("出错了：" + e);
    }
}