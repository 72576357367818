<template>
    <div class="flex h-full">
        <div class="flex">
            <a-layout-sider width="4rem">
                <a-menu mode="inline" :selected-keys="[this.$route.name]" class="bg-[#61696F] text-white" @select="selectMenu">
                    <a-menu-item key="Files" style="padding:0; margin:0;" class="flex flex-col items-center justify-center border-b border-white">
                        <Files class="h-6 pr-2" :active="Menu.Files"/>
                        <label class="text-xs leading-5 cursor-pointer">FILES</label>
                    </a-menu-item>
                    <a-menu-item key="Discover" style="padding:0; margin:0;" class="flex flex-col items-center justify-center border-b border-white">
                        <Discover class="h-6 pr-2" :active="Menu.Discover"/>
                        <label class="text-xs leading-5 cursor-pointer">DISCOVER</label>
                    </a-menu-item>
                    <a-menu-item key="Filter" style="padding:0; margin:0;" class="flex flex-col items-center justify-center border-b border-white">
                        <Filtericon class="h-6 pr-2" :active="Menu.Filter"/>
                        <label class="text-xs leading-5 cursor-pointer">FILTER</label>
                    </a-menu-item>
                    <a-menu-item key="Analyze" style="padding:0; margin:0;" class="flex flex-col items-center justify-center border-b border-white">
                        <Analyze class="h-6 pr-2" :active="Menu.Analyze"/>
                        <label class="text-xs leading-5 cursor-pointer">ANALYZE</label>
                    </a-menu-item>
                    <a-menu-item key="Compare" style="padding:0; margin:0;" class="flex flex-col items-center justify-center border-b border-white">
                        <Compare class="h-6 pr-2" :active="Menu.Compare"/>
                        <label class="text-xs leading-5 cursor-pointer">COMPARE</label>
                    </a-menu-item>
                    <a-menu-item key="Design" style="padding:0; margin:0;" class="flex flex-col items-center justify-center">
                        <Design class="h-6 pr-2" :active="Menu.Design"/>
                        <label class="text-xs leading-5 cursor-pointer">DESIGN</label>
                    </a-menu-item>
                </a-menu>
            </a-layout-sider>
            <div class="flex mr-3" v-show="showSibar">
                <a-layout-sider v-model="collapsed" :trigger="null" collapsible collapsedWidth="0" width="300px" class="relative">
                    <div class="w-full h-full bg-[#EEEFEF]">
                        <router-view name="leftSidebar"/>
                    </div>
                </a-layout-sider>
            </div>
        </div>
        <div class="flex justify-end self-center text-[#66b2ff] h-full items-center ml-[-.75rem] bg-white" v-show="showSibar">
            <div class="w-3 h-16 items-center flex justify-end bg-[#EEEFEF]">
                <a-icon
                    class="trigger"
                    :type="collapsed ? 'right' : 'left'"
                    @click="clickTrigger"
                />   
            </div>
        </div>
    </div>
</template>
<script>
import Analyze from "../../components/icon/analyze.vue"
import Compare from "../../components/icon/compare.vue"
import Design from "../../components/icon/design.vue"
import Discover from "../../components/icon/discover.vue"
import Files from "../../components/icon/files.vue"
import Filtericon from "../../components/icon/filter.vue"
import { message } from 'ant-design-vue';
export default {
    components:{
        Filtericon,
        Files,
        Discover,
        Design,
        Compare,
        Analyze
    },
    data() {
        return {
            collapsed:false,
            showSibar:true,
            Menu:{
                Files:false,
                Discover:false,
                Filter:false,
                Analyze:false,
                Compare:false,
                Design:false,
            },
        };
    },
    watch:{
        '$route'(to,from){
            this.ResetMenu()
            this.Menu[to.name] = true
            if(to.name == "Discover" || to.name == "Filter"){
                this.showSibar = true
            }
            else{
                this.showSibar = false
            }
            if(to.name == "Files"){
                this.$store.dispatch("Filter/clear_tempFilter")
                this.$store.dispatch('Funnel/set_funnelStatus_none')
                localStorage.filter_id = undefined
                localStorage.filter_name = undefined
            }
        }
    },
    mounted(){
        this.Menu[this.$route.name] = true
        if(this.$route.name == "Discover" || this.$route.name == "Filter"){
            this.showSibar = true
        }
        else{
            this.showSibar = false
        }
        if(this.$route.name == "Files"){
            message.info('Double click to load data')
        }
    },
    methods:{
        /**
         * open / close leftsidebar
         */
        clickTrigger(){
            this.collapsed = !this.collapsed;
        },
        ResetMenu(){
            this.Menu.Files = false
            this.Menu.Discover = false
            this.Menu.Filter = false
            this.Menu.Analyze = false
            this.Menu.Compare = false
            this.Menu.Design = false
        },
        /**
         * click menu to another page
         * @param {object} item
         */
        selectMenu(item){
            if(this.Menu[item.selectedKeys[0]] == true) return
            if(localStorage.Log_id == null){
                message.info('Please select an event log')
                return
            }
            this.ResetMenu()
            this.Menu[item.selectedKeys[0]] = true
            if(item.selectedKeys[0] != "Files"){
                this.$router.push({path:'/' + item.selectedKeys[0], query:{Log_id: localStorage.Log_id}})
            }
            else{
                this.$router.push(item.selectedKeys[0])
            }
        },
    }
}
</script>
<style scoped>
.ant-menu-inline > .ant-menu-item{
    line-height: unset;
}
.ant-menu-inline .ant-menu-item{
    height: 4rem;
    
}
</style>