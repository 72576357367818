<template>
    <div class="h-full flex flex-col">
        <div class="flex h-8 mx-2 mt-2">
            <div class="mr-2 px-4 flex justify-center items-center cursor-pointer" 
                :class="Tab == 'Filter'?EnableClass:''"
                @click="changeTab('Filter')"
            >
                <label class="text-l cursor-pointer">Filter</label>
            </div>
            <div class="mr-2 px-4 flex justify-center items-center cursor-pointer"
                :class="Tab == 'Funnel'?EnableClass:''"
                @click="changeTab('Funnel')"
            >
                <label class="text-l cursor-pointer">Funnel</label>
            </div>
        </div>
        <div class="mx-2 mb-4 bg-white border border-[#66b2ff] h-[90%]">
            <FilterTab v-if="Tab === 'Filter'" />
            <FunnelTab v-if="Tab === 'Funnel'" />
        </div>
    </div>        
</template>
<script>
import FilterTab from "./Filter/index.vue"
import FunnelTab from "./Funnel/index.vue"
export default {
    components:{
        FilterTab,
        FunnelTab
    },
    data(){
        return{
            Tab:"Filter",
            EnableClass:'bg-white border-x border-t border-[#66b2ff] mb-[-1px] z-10 pb-[2px]'
        }
    },
    methods:{
        /**
         * 切換tab顯示內容
         * @param {string} tabname Filter / Funnel
         */
        changeTab(tabname){
            this.Tab = tabname
        },
    }
}
</script>