<template>
    <div class="pt-2 h-full">
        <menu-tab></menu-tab>
    </div>
</template>
<script>
import MenuTab from "./MenuTab/index.vue"

export default {
    components:{
        MenuTab
    },
}
</script>