<template>
    <div class="flex h-full text-[#61696F]" v-if="FetchInfoStatus == 'finish' || FetchInfoStatus == 'fail'">
        <div class="z-10 bg-[#EEEFEF] w-3 h-full fixed ml-[-.75rem]"></div>
        <div class="flex flex-col w-full">
            <label class="mx-2 mt-4 mb-0 pl-2 text-[#66b2ff] bg-white leading-[2rem] border border-[#66b2ff] rounded-t">Log Statistics</label>
            <!-- Log Summary -->
            <div class="mx-2 p-2 border-x border-b border-[#66b2ff] bg-white grid-cols-6 gap-6 grid rounded-b">
                <div class="flex flex-col">
                    <label class="border-b pb-1 font-medium">Cases</label>
                    <label class="font-medium text-3xl text-[#66b2ff] mt-2">{{Cases.ratio}}</label>
                    <label class="ml-1">{{Cases.count}}/{{Cases.total}}</label>
                    <div class="flex mt-2 h-3">
                        <div :style="barWidth(Cases.ratio)" class="bg-[#66b2ff] border-[#66b2ff] border"></div>
                        <div :style="barEmptyWidth(Cases.ratio)" class="border-[#66b2ff] border"></div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <label class="border-b pb-1 font-medium">Traces</label>
                    <label class="font-medium text-3xl text-[#66b2ff] mt-2">{{Traces.ratio}}</label>
                    <label class="ml-1">{{Traces.count}}/{{Traces.total}}</label>
                    <div class="flex mt-2 h-3">
                        <div :style="barWidth(Traces.ratio)" class="bg-[#66b2ff] border-[#66b2ff] border"></div>
                        <div :style="barEmptyWidth(Traces.ratio)" class="border-[#66b2ff] border"></div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <label class="border-b pb-1 font-medium">Activity Instances</label>
                    <label class="font-medium text-3xl text-[#66b2ff] mt-2">{{ActivityInstances.ratio}}</label>
                    <label class="ml-1">{{ActivityInstances.count}}/{{ActivityInstances.total}}</label>
                    <div class="flex mt-2 h-3">
                        <div :style="barWidth(ActivityInstances.ratio)" class="bg-[#66b2ff] border-[#66b2ff] border"></div>
                        <div :style="barEmptyWidth(ActivityInstances.ratio)" class="border-[#66b2ff] border"></div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <label class="border-b pb-1 font-medium">Activities</label>
                    <label class="font-medium text-3xl text-[#66b2ff] mt-2">{{Activities.ratio}}</label>
                    <label class="ml-1">{{Activities.count}}/{{Activities.total}}</label>
                    <div class="flex mt-2 h-3">
                        <div :style="barWidth(Activities.ratio)" class="bg-[#66b2ff] border-[#66b2ff] border"></div>
                        <div :style="barEmptyWidth(Activities.ratio)" class="border-[#66b2ff] border"></div>
                    </div>
                </div>
                
                <div class="flex flex-col">
                    <label class="border-b pb-1 font-medium">Log Timeframe</label>
                    <div class="h-full w-full flex flex-col justify-around">
                        <div class="flex w-full mt-2">
                            <div class="flex justify-center w-full">
                                <label class="text-[#66b2ff] text-sm">{{LogTimeframe.fromTime}}</label>
                            </div>
                        </div>
                        <a-icon type="down" class="text-[#66b2ff]" />
                        <div class="flex w-full">
                            <div class="flex justify-center w-full">
                                <label class="text-[#66b2ff] text-sm">{{LogTimeframe.toTime}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <label class="border-b pb-1 font-medium">Case Duration</label>
                    <div class="flex w-full mt-2">
                        <label class="w-1/2 flex justify-end ml-[-1rem]">Min</label>
                        <label class="w-1/2 text-[#66b2ff] flex justify-start ml-[1rem]">{{min}}</label>
                    </div>
                    <div class="flex w-full mt-1">
                        <label class="w-1/2 flex justify-end ml-[-1rem]">Median</label>
                        <label class="w-1/2 text-[#66b2ff] flex justify-start ml-[1rem]">{{median}}</label>
                    </div>
                    <div class="flex w-full mt-1">
                        <label class="w-1/2 flex justify-end ml-[-1rem]">Average</label>
                        <label class="w-1/2 text-[#66b2ff] flex justify-start ml-[1rem]">{{average}}</label>
                    </div>
                    <div class="flex w-full mt-1">
                        <label class="w-1/2 flex justify-end ml-[-1rem]">Max</label>
                        <label class="w-1/2 text-[#66b2ff] flex justify-start ml-[1rem]">{{max}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex';
import TimeLabel from "../../../module/TimeLabel.js"
import numberLabel from "../../../module/numberLabel.js"
import moment from 'moment';
export default {
    computed:{
        ...mapGetters({
            EventLogInfo: "EventLogInfo/get_EventLogInfo",
            FetchInfoStatus:"EventLogInfo/get_InfoFetchStatus"
        }),
    },
    data() {
        return{
            Cases:{
                ratio:'',
                count:0,
                total:0,
            },
            Traces:{
                ratio:'',
                count:0,
                total:0,
            },
            ActivityInstances:{
                ratio:'',
                count:0,
                total:0,
            },
            Activities:{
                ratio:'',
                count:0,
                total:0,
            },
            LogTimeframe:{
                fromTime:"",
                toTime:"",
            },
            CaseDuration:{
                Min:"",
                Median:"",
                Average:"",
                Max:"",
            },
            min:"",
            max:"",
            median:"",
            average:"",
        }
        
    },
    watch:{
        FetchInfoStatus:{
            handler:function(val,oldVal){
                if(val === 'finish'){
                    this.GetLabel()
                }
                else if(val === 'fail'){
                    this.GetEmptyLabel()
                }
            }
        },
    },
    mounted() {
        this.$store.dispatch('EventLogInfo/fetch_EventLogInfo')
    },
    methods:{
        barWidth(percent){
            return "width:" + percent
        },
        barEmptyWidth(percent){
            return "width:" + (100 - parseInt(percent.substr(0,percent.length-1))).toString() + "%"
        },
        GetLabel(){
            //total
            this.Cases.total = numberLabel(this.EventLogInfo.cases.total)
            this.ActivityInstances.total = numberLabel(this.EventLogInfo.task_instances.total)
            this.Activities.total = numberLabel(this.EventLogInfo.tasks.total)
            this.Traces.total = numberLabel(this.EventLogInfo.traces.total)
            //count
            this.Cases.count = numberLabel(this.EventLogInfo.cases.count)
            this.ActivityInstances.count = numberLabel(this.EventLogInfo.task_instances.count)
            this.Activities.count = numberLabel(this.EventLogInfo.tasks.count)
            this.Traces.count = numberLabel(this.EventLogInfo.traces.count)
            //percent
            this.Cases.ratio = this.getPercentlabel(this.EventLogInfo.cases.ratio)
            this.ActivityInstances.ratio = this.getPercentlabel(this.EventLogInfo.task_instances.ratio)
            this.Activities.ratio = this.getPercentlabel(this.EventLogInfo.tasks.ratio)
            this.Traces.ratio = this.getPercentlabel(this.EventLogInfo.traces.ratio)
            if(this.EventLogInfo.case_duration == null)return
            //log timeframe
            this.LogTimeframe.fromTime = moment(this.EventLogInfo.started_at).format('YYYY-MM-DD HH:mm:ss')
            
            this.LogTimeframe.toTime = moment(this.EventLogInfo.completed_at).format('YYYY-MM-DD HH:mm:ss')

            this.min = TimeLabel(this.EventLogInfo.case_duration.min)
            this.max = TimeLabel(this.EventLogInfo.case_duration.max)
            this.median = TimeLabel(this.EventLogInfo.case_duration.median)
            this.average = TimeLabel(this.EventLogInfo.case_duration.average)
        },
        GetEmptyLabel(){
            //total
            this.Cases.total = "-"
            this.ActivityInstances.total = "-"
            this.Activities.total = "-"
            this.Traces.total = "-"
            //count
            this.Cases.count = "-"
            this.ActivityInstances.count = "-"
            this.Activities.count = "-"
            this.Traces.count = "-"
            //percent
            this.Cases.ratio = this.getPercentlabel(0)
            this.ActivityInstances.ratio = this.getPercentlabel(0)
            this.Activities.ratio = this.getPercentlabel(0)
            this.Traces.ratio = this.getPercentlabel(0)
            //log timeframe
            this.LogTimeframe.fromTime = "No Data"
            
            this.LogTimeframe.toTime = "No Data"

            this.min = "No Data"
            this.max = "No Data"
            this.median = "No Data"
            this.average = "No Data"
        },
        getPercentlabel(val){
            if(val * 100 == 100){
                return (val*100) + "%"
            }
            return (val*100).toFixed(1) + "%"
        },
    },
}
</script>
