import * as d3 from "d3";
import moment from 'moment';
import ColorArray from '../enum/ColorArray.js';
/**
 * this function will draw a LineChart for Analyze
 * @param {string} divID Chart will be print on this DivID
 * @param {Object} obj   this object contains { data:array, x_axis:Object, y_axis:Object } , and data array will contain an Object { x:time, y:number }
 */
export default function LineChart(divID,obj) {
    // set the dimensions and margins of the graph
    d3.select("#" + divID).select("svg").remove();
    var margin = {top: 10, right: 30, bottom: 30, left: 40},
        width = 400 - margin.left - margin.right,
        height = 170 - margin.top - margin.bottom;

    const svg = d3.select("#" + divID)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom);
      
    const svgg = svg.append("g")
        .attr("transform",
              "translate(" + margin.left + "," + margin.top + ")");
    
    //set Obj's time to d3 format              
    var data = []
    const parseTime = d3.timeParse('%Y-%m-%d %I:%M:%S')
    obj.data.forEach((element,i)=>{
      Object.keys(element).forEach((e) => {
        if(element[e].y != null){
          data.push({
            name:i,
            x:parseTime(moment(element[e].x).format("YYYY-MM-DD HH:mm:ss")),
            y:element[e].y
          })
        }
      })
    })
    obj.x_axis.min = parseTime(moment(obj.x_axis.min).format("YYYY-MM-DD HH:mm:ss"))
    obj.x_axis.max = parseTime(moment(obj.x_axis.max).format("YYYY-MM-DD HH:mm:ss"))

    var sumstat = d3.group(data, d => d.name);

    // Add X axis --> it is a date format
    var x = d3.scaleTime()
      .domain(d3.extent(data, d => d.x))
      .range([ 0, width ]);
    
    if(parseInt(moment(obj.x_axis.max).valueOf() - moment(obj.x_axis.min).valueOf()) / 1000 / 60 /60 / 24 > 120){
      svgg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%b")));
    }
    else{
      svgg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x));
    }
    

    // Add Y axis
    var y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.y)])
      .range([ height, 0 ]);
    
    svgg.append("g")
      .call(d3.axisLeft(y))
      .call(g => g.selectAll(".tick line").clone()
          .attr("x2", width)
          .attr("stroke-opacity", 0.1))
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll("text").remove())
      .call(g => g.selectAll(".tick line:first-child").remove())
      ;
    
    // color palette
    var res = Array.from(sumstat.keys()); // list of group names

    var color = d3.scaleOrdinal()
      .domain(res)
      .range(ColorArray.LineChart)
    
    svgg.append("g")
      .selectAll("path")
      .data(sumstat)
      //.filter(function(d) { return d.y != null })
      .join("path")
        .attr('fill', 'none')
        .attr('stroke-width', 1.5)
        .attr('stroke', d => color(d[0]))
        .attr("d", d => {
            return d3.line()
              .curve(d3.curveMonotoneX)
              .x(d => x(d.x))
              .y(d => y(d.y))
              (d[1])
          });
    //add circle
    var Circle = svgg.selectAll(".circle")
      .data(data)
    
    Circle.enter().append("circle")
      .attr("class", "circle")
      .attr("r", 3.5)
      .attr("cx", function(d) {
        return x(d.x)
      })
      .attr("cy", function(d) {
        return y(d.y)
      })
      .style("fill", d => color(d.name))

}