<template>
    <div class="flex w-full h-full">
        <AttributesPage v-if="name=='Attributes'" />
        <TimeframesPage v-if="name=='Timeframes'" />
        <SequencePage v-if="name=='Sequence'" />
        <TracesPage v-if="name=='Traces'" />
    </div>
</template>
<script>
import AttributesPage from "./Attributes/index.vue"
import TimeframesPage from "./Timeframes/index.vue"
import SequencePage from "./Sequence/index.vue"
import TracesPage from "./Traces/index.vue"
export default {
    components:{
        AttributesPage,
        TimeframesPage,
        SequencePage,
        TracesPage
    },
    data(){
        return{
            name:"Sequence"
        }
    },
    created(){
        this.$EventBus.$on("SetFilter",this.SetFilter)
    },
    methods:{
        SetFilter(name){
            this.name = name
        }
    }
}
</script>