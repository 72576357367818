<template>
    <div class="flex flex-col relative h-full">
        <div class="w-full flex">
            <img src="../../assets/DSP_LOGO.png" class="h-10"/>
        </div>
        <div class="flex w-full bg-[#61696F] text-white justify-between shadow-md shadow-black:opacity-25 z-20 h-[2.5rem]">
            <div class="pl-2 flex w-1/3">
                <span class="m-2 leading-6 pr-4 cursor-pointer border-r border-white "
                  v-show="$route.name == 'Discover' || $route.name == 'Filter'"
                  @click="Save"
                >
                  Save
                </span>
                <span class="m-2 leading-6 pr-4 cursor-pointer"
                  v-show="$route.name == 'Discover' || $route.name == 'Filter'"
                  @click="SaveFilter"
                >
                  Save As
                </span>
            <!--
              border-r border-white
                <span class="m-2 leading-6 border-r border-white pr-4">Save as</span>
                <span class="m-2 leading-6 border-r border-white pr-4">Print</span>
                <span class="m-2 leading-6">Share</span>
            -->
            </div>
            <div class="pl-2 flex items-center w-1/3 justify-center">
                <a-breadcrumb class="flex text-[#c0c0c0]">
                    <a-breadcrumb-item>{{name}}</a-breadcrumb-item>
                    <a-breadcrumb-item>{{$route.name}}</a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <div class="w-1/3"></div>
        </div>
    </div>
</template>
<script>
import { message } from 'ant-design-vue';
export default {
  components:{
    
  },
  watch:{
    '$route'(to,from){
      if(to.name != "Files"){
        this.name = localStorage.Log_name
      }
      else{
          this.name = "Home"
      }
    }
  },
  mounted(){
    this.name = this.$route.name == "Files" ? "Home" : localStorage.Log_name
  },
  data() {
    return {
      name:"Home"
    };
  },
  methods: {
    /**
     * click save as filter
     */
    SaveFilter(){
      if(localStorage.tempFilter != undefined && localStorage.tempFilter != "undefined"){
        this.$EventBus.$emit("setName","SaveAsFilter")
      }
    },
    /**
     * click save filter
     */
    Save(){
      if(localStorage.filter_id == "undefined"){
        message.warning("Unable to overwrite original file.")
      }
      if(localStorage.tempFilter != undefined && localStorage.tempFilter != "undefined" && localStorage.filter_id != "undefined"){
        this.$EventBus.$emit("setName","SaveFilter")
        //this.$EventBus.$emit("setName","Filter")
      }
    },
  },
};
</script>
<style>
.ant-breadcrumb > span:last-child{
    color:white !important;
}
</style>