//import cytoscape from 'cytoscape';
import cytoscape from 'cytoscape';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
/**
 * draw processmap for trace
 * @param {array}  nodes array of an object, it contains 
 * data:{
 *  backgroundColor: string
 *  bordercolor: string
 *  height: number
 *  id: number
 *  label: string
 *  shape: string
 *  width: number
 * }
 * @param {array}  edges it's similar to nodes
 * @param {string} divID 
 */
export default function ProcessMap(nodes,edges,divID) {
    console.log(nodes)
    console.log(edges)
    var cy = cytoscape({
        container: divID,
        layout: {
            name: 'dagre',
            rankDir: 'LR'
            
        },
        style: [{
                selector: 'node',
                style: {
                    'label': function(node) {
                        let text = ""
                        if(node.data("label").length > 18){
                            text =  `${node.data("label").substr(0,15)}...`
                        }
                        else{
                            text =  `${node.data("label")}`
                        }
                        return text
                      },
                    'text-opacity':0.7,
                    'background-color': 'data(backgroundColor)',
                    'border-color':'data(bordercolor)',
                    'border-width': "1",
                    //'border-radius': '5',
                    'shape':'data(shape)',
                    'text-wrap': 'wrap',
                    'text-max-width':75,
                    'text-halign': 'center',
                    'text-valign': 'center',
                    'height': 'data(height)',
                    'width': 'data(width)',
                    'color': '#001933',
                    'font-size':14,
                }
            },
            {
                selector: 'edge',
                style: {
                    'curve-style': 'taxi',
                    //'curve-style': 'unbundled-bezier',
                    'target-arrow-shape': 'triangle',
                    'color': 'gray', //#0066cc
                    'width':'data(lineWidth)',
                    'line-style':'data(style)',

                }
            },
            {
                selector:'node:selected',
                style:{
                    'border-color':'red',
                    'border-width':'3',

                }
            },
        ],

        elements: {
            nodes: nodes,
            edges: edges,
        }
    });
    /*.connectedEdges().style({
        'color':'red',
    })
    cy.nodes(':selected').style({
        'background-color':'red'
    })
    
    cy.on('click',(e)=>{
        e.target.style({
        'background-color':'red'
        })
    })
    */
    var tip
    cy.on('mouseover', 'node', function(event) {
        var node = event.target
        let ref = node.popperRef()
        let dummyDomEle = document.createElement('div');
        let content = document.createElement('div');
        content.innerHTML = node.data("label")
        tip = new tippy(dummyDomEle, { // tippy props:
            getReferenceClientRect: ref.getBoundingClientRect,
            trigger: 'manual',
            content:content
         });
        //if(node.data("label").length > 18){
            tip.show();
        //}
    })
    cy.on('mouseout', 'node', function(event) {
        tip.hide();
    })
}