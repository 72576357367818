import Vue from 'vue';
import Vuex from 'vuex'
import VueResource from 'vue-resource';
Vue.use(VueResource);
Vue.use(Vuex)
function init_Filter(){
    return{
        Data:{},
        fetchFilterStatus:'none',
        tempFilter:[],
        Trace_more:{},
        Trace_more_status:'none',
    }
}
const state = init_Filter();
const getters = {
    //All tasks
    get_Filter_tasks: state => {
        return state.Data.tasks;
    },
    //form start to end tasks
    get_Filter_start_ends: state => {
        return state.Data.sources;
    },
    //form end to start tasks
    get_Filter_end_starts: state => {
        return state.Data.sinks;
    },
    get_Filter_timeframe: state => {
        return state.Data.timeframe;
    },
    get_Filter_trace: state => {
        return state.Data.traces;
    },
    get_Filter_trace_more: state => {
        return state.Trace_more;
    },
    get_Trace_more_status: state => {
        return state.Trace_more_status;
    },
    get_FilterFetchStatus: state => {
        return state.fetchFilterStatus;
    },
    get_tempFilter:state =>{
        return state.tempFilter
    },
}
const actions = {
    //get All filter data
    async fetch_Filter({ state, commit }) {
        if (state.fetchFilterStatus === 'fetching') return
        commit('set_Filter_fetch_status', 'fetching')
        Vue.http
            .get("/api/event-logs/" + localStorage.Log_id + "/filters/params")
            .then(res => {
                commit('Set_Filter', JSON.parse(JSON.stringify(res.body)));
                commit('set_Filter_fetch_status', 'finish')
            })
    },
    //get Teace more data
    fetch_Trace_more({ state, commit },id) {
        if (state.fetchFilterStatus === 'fetching') return
        commit('set_Trace_more_status', 'fetching')
        Vue.http
            .get("/api/event-logs/" + localStorage.Log_id + "/traces/" + id)
            .then(res => {
                commit('Set_Trace_more', JSON.parse(JSON.stringify(res.body)));
                commit('set_Trace_more_status', 'finish')
            })
    },
    //set filter(有新增檔案)
    Set_Filter({commit},name){
        var obj = {
            name:name,
            rules:JSON.parse(localStorage.tempFilter)
        }
        Vue.http
            .post("/api/event-logs/" + localStorage.Log_id + "/filters/create",obj)
            .then(res => {
                if(res.status == 200){
                    localStorage.filter_id = res.body.id
                }
            })

    },
    //save filter(無新增檔案)
    Save_Filter({commit},name){
        var obj = {
            name:name,
            rules:JSON.parse(localStorage.tempFilter),
        }
        Vue.http
            .put("/api/event-logs/" + localStorage.Log_id + "/filters/" + localStorage.filter_id,JSON.stringify(obj))
            .then(res => {
                if(res.status == 200){
                    console.log('success')
                }
            })
    },
    set_Filter_fetch_status({ commit }){
        commit('set_Filter_fetch_status', 'none')
    },
    set_tempFilter({commit},data){
        commit('set_tempFilter',data)
    },
    clear_tempFilter({commit}){
        commit("clear_tempFilter")
    },
    delete_filter({commit},obj){
        Vue.http
            .delete("/api/event-logs/" + obj.id + "/filters/" + obj.filter_id)
            .then(res => {
                if(res.status == 200){
                    console.log('success')
                }
            })
    }
}
const mutations = {
    Set_Filter(state, Filter) {
        state.Data = Filter;
    },
    set_Filter_fetch_status(state, status) {
        state.fetchFilterStatus = status;
    },
    set_tempFilter(state,data){
        state.tempFilter.push(data)
        //console.log(state.tempFilter)
        localStorage.tempFilter = JSON.stringify(state.tempFilter)
    },
    clear_tempFilter(state){
        state.tempFilter = []
        localStorage.tempFilter = undefined
    },
    Set_Trace_more(state,data){
        state.Trace_more = data
    },
    set_Trace_more_status(state,status){
        state.Trace_more_status = status
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
