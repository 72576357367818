<template>
    <a-select :default-value="options[0].value" @change="handleChange" class="w-full" data-test="dropdown">
        <a-select-option v-for="option in options" :key="option.value" :value="option.value" :disabled="option.disabled" data-test="dropdown_opt">
            {{option.label}}
        </a-select-option>
    </a-select>
</template>
<script>
export default{
    name:"dropdown",
    props:["options"],
    methods:{
        /**
        * 當dropdown修改時會觸發，並修改選項
        * @param {string} value dropdown所選的option
        */
        handleChange(value) {
            this.$EventBus.$emit("SentOptVal",value)
        },
    },
}
</script>
