<template>
    <div class="absolute z-20 top-[40%] left-[40%] w-[20%] h-[40%] ">
        <div class="my-2 border border-[#66b2ff] bg-white flex flex-col w-full rounded">
            <div class="border-b border-[#66b2ff] flex justify-between items-center">
                <span class="text-[#66b2ff] ml-2 leading-8">Enter filter's name</span>
                <a-icon type="close-circle" @click="close" theme="filled" class="text-[#66b2ff] mr-2" />
            </div>
            <a-input placeholder="enter name" allow-clear v-model="name" class="my-2 px-2" data-test="a-input" />
            <a-button @click="submit" class="w-1/5 self-end mr-2 mb-2">OK</a-button>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            name:(localStorage.filter_name == undefined || localStorage.filter_name == "undefined")?"":localStorage.filter_name,
        }
    },
    methods: {
        /**
        * 送出input修改的字串
        */
        submit(){
            if(this.name == "") return
            this.$EventBus.$emit("returnEnterName",this.name)
        },
        /**
        * 關閉該component
        */
        close(){
            this.$EventBus.$emit("closeEntername")
        }
    },
};
</script>