import * as d3 from "d3";
/**
* this function will draw an AreaChart for Trace
* @param {array}  DefaultDataArr it contains Object { key:number, xlabel:number, value:number }
* @param {string} divID          Chart will be print on this DivID
* @param {array}  SelectArea     Chosen slider area
*/
export default function AreaChart(DefaultDataArr,divID,SelectArea) {
    // 定義svg的寬度和高度
    var width = 315,
    height = 300,
    margin = {left:45,top:10,right:5,bottom:20},
    // 圖表的寬度=svg的寬度減去margin
    g_width = width - margin.left - margin.right,
    g_height = height - margin.top - margin.bottom,
    xdata = DefaultDataArr=>DefaultDataArr.key,
    ydata = DefaultDataArr=>DefaultDataArr.value;

    const X = d3.map(DefaultDataArr, xdata)
    const Y = d3.map(DefaultDataArr, ydata);
    const I = d3.range(X.length);

    let defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
    const D = d3.map(DefaultDataArr, defined);
    const xDomain = d3.extent(X)
    const yDomain = [d3.min(Y), d3.max(Y)];
    const xScale = d3.scaleLinear(xDomain, [margin.left,width-margin.right]);
    const yScale = d3.scaleLinear(yDomain, [height - margin.bottom, margin.top]);

    //tickValues(xAxis_ratio).
    const xAxis = d3.axisBottom(xScale).tickValues(X).tickSizeOuter(0).tickFormat(function(d){
        return DefaultDataArr[d-1].xlabel.toFixed(0)+'%';
    })
    const yAxis = d3.axisLeft(yScale).ticks(height / 40);
    
    const area = d3.area()
      .defined(i => D[i])
      .curve(d3.curveBasis)
      .x(i => xScale(X[i]))
      .y0(g_height + margin.top)
      .y1(i => yScale(Y[i]));

    const svg = d3.select("#"+divID)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
    
    svg.append("g")
        .attr("transform", `translate(${margin.left},0)`)
        .call(yAxis)
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll(".tick line").clone()
            .attr("x2", width - margin.left - margin.right)
            .attr("stroke-opacity", 0.1))
    
    var grad = svg.append("defs")
        .append("linearGradient")
        .attr("id", "grad");
    
    const PicPercent = 100/(xDomain[1] - xDomain[0])
    grad.append("stop").attr("offset", "0%").attr("stop-color", "#EEEFEF");
    grad.append("stop").attr("offset", `${(SelectArea[0]-xDomain[0]) * PicPercent}%`).attr("stop-color", "#EEEFEF");

    grad.append("stop").attr("offset", `${(SelectArea[0]-xDomain[0]) * PicPercent}%`).attr("stop-color", "#5FACDD");
    grad.append("stop").attr("offset", `${100-((xDomain[1] - SelectArea[1]) * PicPercent)}%`).attr("stop-color", "#5FACDD");

    grad.append("stop").attr("offset", `${100-((xDomain[1] - SelectArea[1]) * PicPercent)}%`).attr("stop-color", "#EEEFEF");
    grad.append("stop").attr("offset", "100%").attr("stop-color", "#EEEFEF");

    svg.append("path")
        .attr("fill", "url(#grad)")
        .attr("d", area(I));
      
    svg.append("g")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(xAxis);
    
}