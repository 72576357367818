<template>
    <div class="h-[95%] w-[97%] flex flex-col text-[#61696F] absolute z-20">
        <div class="h-2/5 w-full flex">
            <div class="my-2 border border-[#66b2ff] bg-white flex flex-col w-full rounded">
                <div class="border-b border-[#66b2ff] flex justify-between items-center">
                    <span class="text-[#66b2ff] ml-2 leading-8">Trace map</span>
                    <a-icon type="close-circle" @click="close" theme="filled" class="text-[#66b2ff] mr-2" />
                </div>
                <div id="ProcessMap" class="h-full w-full relative"></div>
            </div>
        </div>

        <div class="h-3/5 w-full flex">
            <div class="my-2 border border-[#66b2ff] flex bg-white flex-col w-full rounded">
                <div class="border-b border-[#66b2ff]">
                    <span class="text-[#66b2ff] ml-2 leading-8">Case table</span>
                </div>
                <a-table :columns="TracesName" :data-source="ValueList" :scroll="{ y: 180 }">
                </a-table>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex';
import moment from 'moment';
import { message } from 'ant-design-vue';
import ProcessMap from "../../../../module/ProcessMap_Trace.js"
export default {
    computed:{
        ...mapGetters({
            Trace_more: "Filter/get_Filter_trace_more",
            Trace_more_status:"Filter/get_Trace_more_status",
        }),
    },
    watch:{
        Trace_more_status:{
            handler:function(val,oldVal){
                if(val == 'finish'){
                    this.setValueList()
                    this.createmap()
                }
            }
        },
    },
    data(){
        return{
            TracesName:[
                {
                    title: 'Case ID',
                    dataIndex: 'CaseID',
                },
                {
                    title: 'Start time',
                    dataIndex: 'start',
                },
                {
                    title: 'End time',
                    dataIndex: 'complete',
                },

            ],
            ValueList:[],
            ProcessMap:{
                nodes:[],
                edges:[],
            },
        }
    },
    methods: {
        close(){
            this.$EventBus.$emit("closeMore")
        },
        //設定繪圖資料
        createmap(){
            this.ProcessMap.nodes = this.AddnodesData()
            this.ProcessMap.edges = this.AddedgesData()
            this.drawMap()
        },
        //繪圖
        drawMap(){
            const ProcessMapID = document.getElementById('ProcessMap')
            ProcessMap(this.ProcessMap.nodes,this.ProcessMap.edges,ProcessMapID)
        },
        /**
         * 將node資料彙整
         */
        AddnodesData(){
            const nodes = []
            for(let i = 0;i<this.Trace_more.task_seq.length;i++){
                nodes.push({
                    data:{
                        label:this.Trace_more.task_seq[i],
                        id:i,
                        backgroundColor:'#CCE5FF',
                        bordercolor:'#003366',
                        shape:"round-rectangle",
                        height:80,
                        width:100
                    }
                })
            }
            return nodes
        },
        /**
         * 將edge資料彙整
         */
        AddedgesData(){
            const edges = []
            for(let i = 0;i<this.ProcessMap.nodes.length -1 ;i++){
                edges.push({
                    data:{
                        source:i,
                        target:i+1,
                        lineWidth:1,
                        style:"solid"
                    }
                })
            }
            return edges
        },
        //表格資料彙整
        setValueList(){
            this.ValueList = []
            this.Trace_more.cases.forEach((e,i)=>{
                this.ValueList.push({
                    key:i,
                    CaseID: e.id,
                    start:moment(e.started_at).format("YYYY-MM-DD HH:mm:ss"),
                    complete:moment(e.complete_at).format("YYYY-MM-DD HH:mm:ss"),
                });
            })
        },
    },
}
</script>
