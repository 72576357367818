<template>
    <div class="flex flex-col text-[#61696F] bg-[#EEEFEF]">
        <label class="mx-2 mt-4 mb-0 pl-2 text-[#66b2ff] bg-white leading-[2rem] border border-[#66b2ff] rounded-t">Visualization Settings</label>
        <div class="mx-2 p-2 flex flex-col border-x border-b border-[#66b2ff] bg-white rounded-b">
            <div class="flex flex-col">
                <label class="border-b pb-1 font-medium">View</label>
                <div class="flex flex-col justify-center">
                    <!-- 選擇bpmn/processmap button-->
                    <div class="justify-center flex">
                        <div class="flex mt-2 justify-between items-center w-full rounded-xl bg-[#F0EFF4] border border-black">
                            <div class="rounded-xl px-5 cursor-pointer border border-[#F0EFF4]" :class="Viewtype === 'ProcessMap'?'bg-[#66b2ff] text-white':''" @click="changeViewType('ProcessMap')">
                                <label class="cursor-pointer">Process map</label>
                            </div>
                            <div class="rounded-xl px-5 cursor-pointer border border-[#F0EFF4]" :class="Viewtype === 'BPMN'?'bg-[#66b2ff] text-white':''" @click="changeViewType('BPMN')">
                                <label class="ml-2 cursor-pointer">BPMN model</label>
                            </div>
                        </div>
                    </div>
                    <!-- 選擇繪畫樣式 taxi/unbundled-bezier button-->
                    <div class="justify-center flex">
                        <div class="flex mt-2 justify-between items-center w-full rounded-xl bg-[#F0EFF4] border border-black">
                            <div class="rounded-xl px-5 cursor-pointer border border-[#F0EFF4] w-1/2 flex justify-center" :class="Curvetype === 'unbundled-bezier'?'bg-[#66b2ff] text-white':''" @click="changeCurveType('unbundled-bezier')">
                                <label class="ml-2 cursor-pointer">curve</label>
                            </div>
                            <div class="rounded-xl px-5 cursor-pointer border border-[#F0EFF4] w-1/2 flex justify-center" :class="Curvetype === 'taxi'?'bg-[#66b2ff] text-white':''" @click="changeCurveType('taxi')">
                                <label class="cursor-pointer">Taxi</label>
                            </div>
                        </div>
                    </div>
                    <!-- Perspective option -->
                    <div class="flex mt-2 items-center w-full justify-between">
                        <label class="w-1/3">Perspective</label>
                        <div class="ml-2 w-2/3">
                            <a-select v-model="PerspectiveVal" @change="handleChange" class="w-full">
                                <a-select-option v-for="option in PerspectiveOptions" :key="option.value" :value="option.value" :disabled="option.disabled">
                                    {{option.label}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col mt-4 mb-2">
                <label class="border-b pb-1 font-medium">Data Layer</label>
                <!-- Data Layer radio Group -->
                <a-radio-group v-model="Indicatior" @change="onChange" class="flex flex-col w-full">
                    <div class="flex mt-2 items-center justify-between">
                        <a-radio  value="freq" class="w-1/3">
                            <label>Frequency</label>
                        </a-radio>
                        <!-- Frequency option -->
                        <div class="ml-2 w-3/5">
                            <a-select v-model="FreqVal" @change="handleChange" class="w-full">
                                <a-select-option v-for="option in FrequencyOptions" :key="option.value" :value="option.value" :disabled="option.disabled">
                                    {{option.label}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>

                    <div class="flex mt-2 items-center justify-between">
                        <a-radio  value="duration" class="w-1/3">
                            <label>Duration</label>
                        </a-radio>
                        <!-- Duration option -->
                        <div class="ml-2 w-3/5">
                            <a-select v-model="DurationVal" @change="handleChange" class="w-full">
                                <a-select-option v-for="option in DurationOptions" :key="option.value" :value="option.value" :disabled="option.disabled">
                                    {{option.label}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                </a-radio-group>
            </div>
        </div>


        <label class="mx-2 mt-4 mb-0 pl-2 text-[#66b2ff] bg-white leading-[2rem] border border-[#66b2ff] rounded-t">Abstraction Settings</label>
        <div class="mx-2 p-2 flex flex-col border-x border-b border-[#66b2ff] bg-white rounded-b">
            <div class="flex flex-col">
                <div class="flex items-center w-full justify-between">
                    <label class="w-1/3">Abstract by</label>
                    <!-- Abstract by option -->
                    <div class="ml-2 w-2/3">
                        <a-select v-model="AbstractVal" @change="handleChange" class="w-full">
                            <a-select-option v-for="option in AbstractOptions" :key="option.value" :value="option.value" :disabled="option.disabled">
                                {{option.label}}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
                <!-- eta/epslion slider -->
                <div class="flex items-center w-full justify-between mt-2 text-[#61696F]">
                    <label class="w-1/3"></label>
                    <label class="w-1/3">Low</label>
                    <label class="w-1/3 flex justify-end">High</label>
                </div>
                <div class="flex items-center w-full justify-between" @keyup="emitDiscover">
                    <label class="w-1/3">Concurrency detection</label>
                    <a-slider v-model="mapData.epsilon" :min="0" :max="1" :step="0.1" class="ml-2 w-2/3" @mouseup.native="emitDiscover" :disabled="Viewtype === 'ProcessMap'?true:false" />
                </div>
                <div class="flex items-center w-full justify-between" @keyup="emitDiscover">
                    <label class="w-1/3">Edge retention</label>
                    <a-slider v-model="mapData.eta" :min="0" :max="1" :step="0.01" class="ml-2 w-2/3" @mouseup.native="emitDiscover" :disabled="Viewtype === 'ProcessMap'?true:false" />
                </div>
                <!--<div class="flex items-center w-full justify-between">
                    <label class="w-1/3">Parallelism</label>
                    <a-slider range :default-value="[Parallelism[0],Parallelism[1]]" v-model="Parallelism" class="ml-2 w-2/3" :disabled="Viewtype !='BPMNmodel'"/>
                </div>-->
            </div>
        </div>
    </div>
</template>
<script>
import dropdown from "../../../components/dropdown.vue"
export default {
    components:{
        dropdown,
    },
    data(){
        return{
            BPMNmod:false,
            PerspectiveOptions:[
                {
                    value:0,
                    label:"Activities",
                    disabled:false,
                },
                {
                    value:1,
                    label:"Resources",
                    disabled:true,
                },
            ],
            PerspectiveVal:0,
            FrequencyOptions:[
                {
                    value:"total",
                    label:"Total",
                    disabled:false,
                },
                {
                    value:"rel_freq",
                    label:"Relative",
                    disabled:false,
                },
                {
                    value:"average",
                    label:"Average",
                    disabled:false,
                },
                {
                    value:"median",
                    label:"Median",
                    disabled:false,
                },
                {
                    value:"max",
                    label:"Max",
                    disabled:false,
                },
                {
                    value:"min",
                    label:"Min",
                    disabled:false,
                },
                {
                    value:"cases",
                    label:"Number of cases",
                    disabled:false,
                },
            ],
            FreqVal:"total",
            DurationOptions:[
                {
                    value:"total",
                    label:"Total",
                    disabled:false,
                },
                {
                    value:"rel_duration",
                    label:"Relative",
                    disabled:false,
                },
                {
                    value:"average",
                    label:"Average",
                    disabled:false,
                },
                {
                    value:"median",
                    label:"Median",
                    disabled:false,
                },
                {
                    value:"max",
                    label:"Max",
                    disabled:false,
                },
                {
                    value:"min",
                    label:"Min",
                    disabled:false,
                },
            ],
            DurationVal:"total",
            OtherOptions:[
                {
                    value:0,
                    label:"Custom_1",
                    disabled:true,
                },
                {
                    value:1,
                    label:"Custom_2",
                    disabled:true,
                },
            ],
            OtherVal:0,
            AbstractOptions:[
                {
                    value:0,
                    label:"Total frequency",
                    disabled:false,
                },
                {
                    value:1,
                    label:"Average duration",
                    disabled:true,
                },
            ],
            AbstractVal:0,
            Parallelism:[40,60],
            Indicatior:"freq",
            Viewtype:"ProcessMap",
            mapData:{
                epsilon:0.8,
                eta:0.8,
            },
            Curvetype:"unbundled-bezier",
        }
    },
    methods:{
        onChange(e) {
            this.sendOpt()
        },
        handleChange(value) {
            this.sendOpt()
        },
        //change dropdown or radio button will change processmap/bpmn
        sendOpt(){
            var obj
            if(this.Indicatior == "freq"){
                obj = {
                    optvalue:this.FreqVal,
                    optkey:this.Indicatior
                }
            }
            else if(this.Indicatior == "duration"){
                obj = {
                    optvalue:this.DurationVal,
                    optkey:this.Indicatior
                }
            }
            this.$EventBus.$emit("OptionValue",obj)
        },
        //change eta or epslion
        emitDiscover(){
            console.log('1:key up');
            this.$EventBus.$emit("GetNewMapdata",this.mapData)
            console.log('2:key up');
        },
        //change bpmn or processmap
        changeViewType(type){
            this.Viewtype = type
            this.mapData.epsilon = 0.8
            this.mapData.eta = 0.8
            this.$EventBus.$emit("ChangeMap",this.Viewtype)
        },
        //change curve type
        changeCurveType(type){
            this.Curvetype = type
            this.$EventBus.$emit("ChangeCurve",this.Curvetype)
        }
    },
}
</script>
