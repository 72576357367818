<template>
    <div class="flex flex-col h-full w-full text-[#61696F]">
        <div class="flex w-full justify-between mt-2 px-4">
            <div class="flex w-[90%] flex-wrap">
                <!-- 顯示目前選擇的檔案(不可被刪除) -->
                <div class="border-2 w-1/5 flex items-center border-[#EA698B] text-[#EA698B] m-2">
                    <div class="flex flex-col" v-if="mainLogName.filter_name != undefined && mainLogName.filter_name != 'undefined'">
                        <span class="ml-2 font-bold mb-[-3px]" >{{mainLogName.filter_name}}</span>
                        <span class="ml-2 text-[#888]">{{mainLogName.Log_name}}</span>
                    </div>
                    <div class="flex flex-col" v-else>
                        <span class="ml-2 font-bold mb-[-3px]" >{{mainLogName.Log_name}}</span>
                        <br />
                    </div>
                </div>
                <!-- 顯示新增進來的檔案(可被刪除) -->
                <div class="border-2 w-1/5 flex justify-between items-center m-2" v-for="arr,i in logNameArr" :key="i" :style="{'border-color': color[i+1]}">
                    <div class="flex items-center">
                        <div class="flex flex-col" v-if="arr.filter_name != undefined && arr.filter_name != 'undefined'">
                            <span class="ml-2 font-bold mb-[-3px]" :style="{'color':color[i+1]}">{{arr.filter_name}}</span>
                            <span class="ml-2 text-[#888]" >{{arr.log_name}}</span>
                        </div>
                        <div class="flex flex-col" v-else>
                            <span class="ml-2 font-bold mb-[-3px]" :style="{'color':color[i+1]}">{{arr.log_name}}</span>
                            <br />
                        </div>
                    </div>
                    <a-icon type="close-circle" class="mr-2 cursor-pointer" @click="DelLogArr(arr)" />
                </div>

                <div class="border-2 w-1/5 flex justify-between items-center m-2 cursor-pointer" @click="AddFile">
                    <span class="ml-2">Add file</span>
                    <a-icon type="plus-circle" class="mr-2" />
                </div>
            </div>
            <!-- Analyze暫時沒有新增篩選功能故拿掉該button
            <div class="border border-[#61696F] w-[10%] flex justify-between items-center h-8 cursor-pointer">
                <span class="ml-2">Add filter</span>
                <a-icon type="setting" class="mr-2" />
            </div>-->
        </div>
        <!-- LineChart -->
        <div class="flex w-full flex-col mt-4 pb-4 px-4 text-[#66b2ff] overflow-y-scroll">
            <div class="flex w-full flex-col mt-4">
                <div class="flex border-b w-full pb-2 items-center">
                    <a-icon type="thunderbolt" class="text-l" />
                    <span class="ml-2">How busy ?</span>
                </div>

                <div class="flex mt-4 text-[#555]">
                    <div class="border flex flex-col w-[30%] h-[30vh] ml-4">
                        <span class="flex justify-center my-2">Cases</span>
                        <div class="flex w-full h-[85%] items-center justify-center  pb-4">
                            <div class="mx-2 flex justify-center" id="CaseLineChart" ></div>
                        </div>
                    </div>
                    <div class="border flex flex-col w-[30%] h-[30vh] ml-4">
                        <span class="flex justify-center my-2">Traces</span>
                        <div class="flex w-full h-[85%] items-center justify-center  pb-4">
                            <div class="mx-2 flex justify-center" id="TraceLineChart" ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex w-full flex-col mt-4">
                <div class="flex border-b w-full pb-2 items-center">
                    <a-icon type="clock-circle" class="text-l" />
                    <span class="ml-2">How long ?</span>
                </div>

                <div class="flex mt-4 flex-wrap text-[#555]">
                    <div class="border flex flex-col w-[30%] h-[30vh] ml-4">
                        <span class="flex justify-center my-2">Processing time</span>
                        <div class="flex w-full h-[85%] items-center justify-center  pb-4">
                            <div class="mx-2 flex justify-center" id="ProcessTimeLineChart" ></div>
                        </div>
                    </div>
                    <div class="border flex flex-col w-[30%] h-[30vh] ml-4">
                        <span class="flex justify-center my-2">Waiting time</span>
                        <div class="flex w-full h-[85%] items-center justify-center  pb-4">
                            <div class="mx-2 flex justify-center" id="WaitingTimeLineChart" ></div>
                        </div>
                    </div>
                    <div class="border flex flex-col w-[30%] h-[30vh] ml-4">
                        <span class="flex justify-center my-2">Cycle time</span>
                        <div class="flex w-full h-[85%] items-center justify-center  pb-4">
                            <div class="mx-2 flex justify-center" id="CycleTimeLineChart" ></div>
                        </div>
                    </div>
                    <div class="border flex flex-col w-[30%] h-[30vh] ml-4 mt-4">
                        <span class="flex justify-center my-2">Cycle efficiency</span>
                        <div class="flex w-full h-[85%] items-center justify-center  pb-4">
                            <div class="mx-2 flex justify-center" id="CycleEfficiencyLineChart" ></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 目前沒有Attribute所以有關Attribute的先註解掉
            <div class="flex w-full flex-col mt-4">
                <div class="flex border-b w-full pb-2 items-center">
                    <a-icon type="pay-circle" class="text-l" />
                    <span class="ml-2">How mush / many ?</span>
                </div>

                <div class="flex mt-4 h-[30vh]">
                    <div class="border flex flex-col w-[30%]">
                        <span class="text-[#555]">Cases</span>
                        <div></div>
                    </div>
                </div>
            </div>-->
        </div>
        <AddFile v-if="showAddFile" />
    </div>
</template>
<script>
import LineChart from "../../module/LineChart.js"
import ColorArray from '../../enum/ColorArray.js';
import AddFile from "./AddFile/index.vue"
import { mapGetters,mapActions } from 'vuex';
import { message } from 'ant-design-vue';
export default {
    components:{
        LineChart,
        AddFile
    },
    computed:{
        ...mapGetters({
            Analyze: "Analyze/get_AllAnalyze",
            AnalyzeStatus: "Analyze/get_Analyze_status",
            
        }),
    },
    created(){
        this.$EventBus.$on("AddFilePage",this.AddFilePage)
        this.$EventBus.$on("AddFileData",this.AddFileData)
    },
    watch:{
        AnalyzeStatus:{
            handler:function(val,oldVal){
                if(val == 'finish'){
                    message.destroy()
                    this.GetLineChart()
                }
            },
        }
    },
    data(){
        return{
            mainLogName:{
                Log_name:localStorage.Log_name,
                filter_name:localStorage.filter_name,
            },
            logNameArr:[],
            color:ColorArray.LineChart,
            showAddFile:false,
        }
    },
    beforeMount(){
        this.$store.dispatch("Analyze/set_data_fetch_status")
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        GetLineChart(){
            LineChart("CaseLineChart",this.Analyze.cases)
            LineChart("TraceLineChart",this.Analyze.traces)
            LineChart("ProcessTimeLineChart",this.Analyze.processing_time)
            LineChart("WaitingTimeLineChart",this.Analyze.waiting_time)
            LineChart("CycleTimeLineChart",this.Analyze.cycle_time)
            LineChart("CycleEfficiencyLineChart",this.Analyze.cycle_efficiency)
            
        },
        AddFilePage(){
            this.showAddFile = false
        },
        AddFile(){
            this.$EventBus.$emit("ShowCover")
            this.showAddFile = true
        },
        AddFileData(file){
            this.logNameArr.push(file)
            this.fetchData()    
        },
        //get Data to draw lineChart
        fetchData(){
            message.loading('loading ...',0)
            var arr = []
            //判斷是否有filter的資料
            if(localStorage.filter_id != undefined && localStorage.filter_id != 'undefined'){
                arr.push({
                    log_id:localStorage.Log_id,
                    filter_id:localStorage.filter_id
                })
            }
            else{
                arr.push({
                    log_id:localStorage.Log_id,
                })
            }
            //add file data
            for(let i =0;i<this.logNameArr.length;i++){
                if(this.logNameArr[i].filter_id != undefined){
                    arr.push({
                        log_id:this.logNameArr[i].log_id,
                        filter_id:this.logNameArr[i].filter_id
                    })
                }
                else{
                    arr.push({
                        log_id:this.logNameArr[i].log_id,
                    })
                }
            }
            this.$store.dispatch("Analyze/fetch_Analyze",arr)
        },
        DelLogArr(file){
            const index = this.logNameArr.indexOf(file)
            this.logNameArr.splice(index,1)
            this.fetchData()
        }
    }
}
</script>