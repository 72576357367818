/**
 * this function will get array's key by value string
 * @param {array} array 
 * @param {string} value 
 * @returns 
 */
export default function GetKeyByValue(array,value) {
    for( var key in array ) {
        if(array[key] == value){
            return key;
        }
    }
    return null;
}