import Vue from 'vue'
import Router from 'vue-router'
import MainContainer from '../views/MainContainer.vue'
import DiscoverLeftSidebar from '../views/Discover/Leftsidebar/index.vue'
import DiscoverBottomSidebar from '../views/Discover/Bottomsidebar/index.vue'
import Discover from '../views/Discover/index.vue'
import FilterLeftSidebar from '../views/Filter/Leftsidebar/index.vue'
//import FilterBottomSidebar from '../views/Filter/Bottomsidebar/index.vue'
import Filter from '../views/Filter/index.vue'
import Files from '../views/Files/index.vue'
//import FilesLeftSidebar from '../views/Files/Leftsidebar/index.vue'
import Design from '../views/Design/index.vue'
import Analyze from '../views/Analyze/index.vue'


Vue.use(Router)

const routes = [
  { 
    path:'',
    component:MainContainer,
    children:[
      {
        name:'Files',
        path:'/Files',
        components:{
          //leftSidebar:FilesLeftSidebar,
          container:Files
        }
      },
      {
        name:'Discover',
        path:'/Discover',
        components:{
          leftSidebar:DiscoverLeftSidebar,
          bottomSidebar:DiscoverBottomSidebar,
          container:Discover
        }
      },
      {
        name:'Filter',
        path:'/Filter',
        components:{
          leftSidebar:FilterLeftSidebar,
          container:Filter
        }
      },
      {
        name:'Design',
        path:'/Design',
        components:{
          container:Design
        }
      },
      {
        name:'Analyze',
        path:'/Analyze',
        components:{
          container:Analyze
        }
      }
    ]
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
