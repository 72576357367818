<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
        :stroke="active?'#66b2ff':'white'"
        d="M18 10.5C25.4558 10.5 31.5 9.15685 31.5 7.5C31.5 5.84315 25.4558 4.5 18 4.5C10.5442 4.5 4.5 5.84315 4.5 7.5C4.5 9.15685 10.5442 10.5 18 10.5Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path 
        :stroke="active?'#66b2ff':'white'"
        d="M4.5 7.5C4.5 10.845 10.3065 17.511 13.284 20.7075C14.3762 21.8653 14.9894 23.3938 15 24.9855V33L21 30V24.9855C21 23.394 21.6315 21.873 22.716 20.7075C25.695 17.511 31.5 10.8465 31.5 7.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
<script>
export default {
  props: {
    active: Boolean
  }
};
</script>