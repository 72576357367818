<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
        :fill="active?'#66b2ff':'white'"
        d="M29.5 4.75H18.25V2.5C18.25 1.90326 18.0129 1.33097 17.591 0.90901C17.169 0.487053 16.5967 0.25 16 0.25H2.5C1.90326 0.25 1.33097 0.487053 0.90901 0.90901C0.487053 1.33097 0.25 1.90326 0.25 2.5V25C0.25 25.5967 0.487053 26.169 0.90901 26.591C1.33097 27.0129 1.90326 27.25 2.5 27.25H13.75V29.5C13.75 30.0967 13.9871 30.669 14.409 31.091C14.831 31.5129 15.4033 31.75 16 31.75H29.5C30.0967 31.75 30.669 31.5129 31.091 31.091C31.5129 30.669 31.75 30.0967 31.75 29.5V7C31.75 6.40326 31.5129 5.83097 31.091 5.40901C30.669 4.98705 30.0967 4.75 29.5 4.75ZM2.5 14.875H9.44125L6.53875 17.7887L8.125 19.375L13.75 13.75L8.125 8.125L6.53875 9.71125L9.44125 12.625H2.5V2.5H16V25H2.5V14.875ZM16 29.5V27.25C16.5967 27.25 17.169 27.0129 17.591 26.591C18.0129 26.169 18.25 25.5967 18.25 25V7H29.5V17.125H22.5588L25.4613 14.2113L23.875 12.625L18.25 18.25L23.875 23.875L25.4613 22.2887L22.5588 19.375H29.5V29.5H16Z"/>
    </svg>
</template>
<script>
export default {
  props: {
    active: Boolean
  }
};
</script>